import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import MainHeader from '../header/MainHeader';

const MainLayout = ({ loggedInUserDetails, logoutUser }) => {
  return (
    <div>
      <MainHeader loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser}/>
      <Navbar />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default MainLayout;