import React, { useEffect, useState } from "react";
import {
    Box,
    TextField,
    Button,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Snackbar,
    InputAdornment,
    CircularProgress,
    Autocomplete,
    Checkbox,
    Divider,
    Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { sourceNames } from "./staticVariables";
import { url } from "../../../url";
import axios from "axios";
import { message } from "antd";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import OptoutSubmitConfirmation from "./OptoutSubmitWithConfirmation";
import OptoutSubmitWithConfirmation from "./OptoutSubmitWithConfirmation";
import ChipAddInput from "./ChipAddInput";
import { emailValidationRegex, phoneValidationRegex } from "../../../utils/constants";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ClientSpecificOptOut = () => {
    const [customersData, setCustomersData] = useState({ isLoading: false, data: [] });
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const theme = useTheme();

    const [formData, setFormData] = useState({
        emails: [],
        source: "",
        source_refs: [],
        user_id: "",
        candidate_ids: [`Manual:${crypto.randomUUID()}`],
        country_code: "+1",
        dox_category: null,
        phones: [],
    });

    const handleChipAdd = (e) => {
        if (e.key === "Enter" && e.target.value.trim()) {
            const newValue = e.target.value.trim();
            const key = e.target.name;
            e.preventDefault();
            setFormData((prev) => ({
                ...prev,
                [key]: [...prev[key], newValue],
            }));
            e.target.value = "";
        }
    };

    const handleEmailAdd = (e) => {
        if (e.key === "Enter" && e.target.value.trim()) {
            const newValue = e.target.value.trim();

            if (!emailValidationRegex.test(newValue)) {
                console.log(newValue);
                return { error: true, message: "Please enter a valid email." };
            }

            // Check for duplicates
            if (formData?.emails.includes(newValue)) {
                return { error: true, message: "This email is already added." };
            }
            const key = e.target.name;
            e.preventDefault();
            setFormData((prev) => ({
                ...prev,
                [key]: [...prev[key], newValue],
            }));
            e.target.value = "";
            return { error: false, message: "" };
        }
    };
    const handlePhoneAdd = (e) => {
        if (e.key === "Enter" && e.target.value.trim()) {
            const newValue = e.target.value.trim();

            // Validation for numbers
            if (!phoneValidationRegex.test(newValue)) {
                return { error: true, message: "Please enter a valid phone number." };
            }

            // Check for duplicates
            if (formData?.phones.includes(newValue)) {
                return { error: true, message: "This phone number is already added." };
            }
            const key = e.target.name;
            e.preventDefault();
            setFormData((prev) => ({
                ...prev,
                [key]: [...prev[key], newValue],
            }));
            e.target.value = "";
            return { error: false, message: "" };
        }
    };

    const handleChipDelete = (key, chipToDelete) => {
        setFormData((prev) => ({
            ...prev,
            [key]: prev[key].filter((chip) => chip !== chipToDelete),
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleCustomerNameChange = (event, newValue) => {
        setFormData((prev) => ({ ...prev, user_id: newValue?.userId || "" }));
        setSelectedCustomer(newValue?.label);
    };

    const fetchCustomers = async () => {
        try {
            setCustomersData({ isLoading: true, data: [] });
            const response = await axios.get(url.customersAPI, {
                params: {
                    isStaging: true,
                },
            });
            const customersList = Object.entries(response?.data?.response).map(([accountId, customerData]) => {
                return { value: accountId, label: customerData?.company_name, userId: customerData?.users?.[0] };
            });
            setCustomersData({ isLoading: false, data: customersList });
        } catch (error) {
            setCustomersData({ isLoading: false, data: [] });
            message.error(error?.response?.data?.data?.message || "Failed to load customers!");
        }
    };

    useEffect(() => {
        fetchCustomers();
    }, []);

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.background.paper,
                padding: theme.spacing(4),
                borderRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[2],
            }}
        >
            <Typography variant="h3" sx={{ marginBottom: theme.spacing(3) }}>
                Client Specific Opt-Out Form
            </Typography>
            <form>
                <Grid container spacing={3}>
                    {/* User ID */}
                    <Grid item xs={12}>
                        <Autocomplete
                            sx={{ color: "warning" }}
                            options={customersData?.data || []}
                            getOptionLabel={(option) => option?.label || ""}
                            onChange={handleCustomerNameChange}
                            loading={customersData?.isLoading}
                            disablePortal
                            ListboxProps={{
                                sx: {
                                    maxHeight: "300px",
                                    overflowY: "auto",
                                },
                            }}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={
                                        <>
                                            <Typography
                                                component="span"
                                                sx={{
                                                    color: "warning.main", // Warning color from theme
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                Customer&nbsp;
                                                <Typography component="span" sx={{ color: "error.main" }}>
                                                    *
                                                </Typography>
                                            </Typography>
                                        </>
                                    }
                                    InputProps={{
                                        ...params.InputProps,

                                        endAdornment: (
                                            <>
                                                {customersData?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            renderOption={(props, option, { selected }) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <li key={option.value} {...optionProps}>
                                        <Stack>
                                            <Typography variant="body1">{option.label}</Typography>
                                            <Typography color="text.secondary" variant="body2">
                                                {option.value}
                                            </Typography>
                                        </Stack>
                                    </li>
                                );
                            }}
                        />
                    </Grid>

                    {/* Source */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="source-label">Source</InputLabel>
                            <Select
                                label="Source"
                                labelId="source-label"
                                id="source"
                                name="source"
                                value={formData.source}
                                onChange={handleInputChange}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                            width: 300,
                                        },
                                    },
                                }}
                            >
                                {sourceNames.map((source) => {
                                    return (
                                        <MenuItem key={source} value={source}>
                                            {source}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Source Refs */}
                    <Grid item xs={12}>
                        <TextField
                            label="Source Refs"
                            name="source_refs"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <>
                                        {formData.source_refs.map((ref, index) => (
                                            <Chip
                                                key={index}
                                                label={ref}
                                                onDelete={() => handleChipDelete("source_refs", ref)}
                                                sx={{ marginRight: 0.5 }}
                                            />
                                        ))}
                                    </>
                                ),
                            }}
                            onKeyDown={handleChipAdd}
                            placeholder="Press Enter to add"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ width: "100%" }}>
                            <Typography color={"text.secondary"}>or</Typography>
                        </Divider>
                    </Grid>

                    {/* Emails */}
                    <Grid item xs={12}>
                        <ChipAddInput
                            label={"Emails"}
                            name={"emails"}
                            values={formData?.emails}
                            handleAdd={handleEmailAdd}
                            handleDelete={handleChipDelete}
                        ></ChipAddInput>
                    </Grid>

                    {/* Country Code */}
                    <Grid item xs={12} sm={1}>
                        <TextField
                            label="Country Code"
                            fullWidth
                            name="country_code"
                            value={formData.country_code}
                            onChange={handleInputChange}
                            placeholder="e.g., +1"
                        />
                    </Grid>

                    {/* Phones */}
                    <Grid item xs={12} sm={11}>
                        <ChipAddInput
                            label={"Phones"}
                            name={"phones"}
                            values={formData?.phones}
                            handleAdd={handlePhoneAdd}
                            handleDelete={handleChipDelete}
                        ></ChipAddInput>
                    </Grid>

                    {/* Submit */}
                    <Grid item xs={12}>
                        <OptoutSubmitWithConfirmation profileInfoSearchData={formData} selectedCustomer={selectedCustomer} />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default ClientSpecificOptOut;
