import React from "react";
// import "./index.css";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Card, CardHeader, Divider, IconButton, Typography } from "@mui/material";
import DurationCard from "./DurationCard";

const VolunteerExperienceFrom = ({ fieldPath, name, form }) => {
    return (
        <Card variant="outlined" sx={{ p: 2, mt: 2, backgroundColor: "profileCreatorForm.volunteerExperience" }}>
            <CardHeader sx={{ p: 0 }} title={<Typography variant="body1">Volunteer Experience</Typography>} />
            <Divider sx={{ mb: 3 }} />
            <Form.List name={name}>
                {(volunteerExpFields, { add: addVolunteerExp, remove: removeVolunteerExp }) => (
                    <>
                        {volunteerExpFields.map((volunteerExpField) => (
                            <Card variant="outlined" sx={{ p: 2, mt: 2, backgroundColor: "profileCreatorForm.volunteerExperience" }}>
                                <CardHeader
                                    action={
                                        <IconButton onClick={() => removeVolunteerExp(volunteerExpField.name)} aria-label="settings">
                                            <RemoveCircleIcon />
                                        </IconButton>
                                    }
                                    sx={{ p: 0 }}
                                    title={<Typography variant="body1">{`Volunteer Exp ${volunteerExpField.name + 1}`}</Typography>}
                                />
                                <Divider sx={{ mb: 3 }} />

                                {/* Other Fields of projects under workexp */}
                                <Form.Item
                                    name={[volunteerExpField.name, "title"]}
                                    fieldKey={[volunteerExpField.fieldKey, "title"]}
                                    label="Title"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item name={[volunteerExpField.name, "company_name"]} label={"Company Name"}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name={[volunteerExpField.name, "cause"]} label={"Cause"}>
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name={[volunteerExpField.name, "description"]}
                                    fieldKey={[volunteerExpField.fieldKey, "description"]}
                                    label="Description"
                                >
                                    <Input.TextArea />
                                </Form.Item>
                                <DurationCard
                                    fieldPath={[...fieldPath, volunteerExpField.name]}
                                    name={[volunteerExpField.name]}
                                    form={form}
                                />
                            </Card>
                        ))}

                        <Button type="dashed" onClick={() => addVolunteerExp()} block icon={<PlusOutlined />}>
                            Add Volunteer Experience
                        </Button>
                    </>
                )}
            </Form.List>
        </Card>
    );
};

export default VolunteerExperienceFrom;
