import { Box, Button, Divider, FormControlLabel, Stack, Switch, ToggleButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import { LEADERSHIP_CONST } from "../../../utils/constants";
const { PROFILE_STATUS } = LEADERSHIP_CONST;
const CustomFormToolbar = ({
    selectedProfile,
    handleProfileViewChange,
    isUpdatedView,
    submitWithError,
    setSubmitWithError,
    setIsJsonView,
    isJsonView,
    lastUpdatedProfile,
    currentFormPath,
    handleOpenDiffViewer
}) => {
    return (
        <>
            <Box
                sx={(theme) => ({
                    position: "sticky",
                    top: theme.custom.top.level3,
                    zIndex: theme.zIndex.navbar,
                    backgroundColor: "background.paper",
                    borderBlockEnd: theme.palette.border.primary,
                })}
            >
                <Stack>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        sx={{
                            padding: "10px",
                        }}
                    >
                        {(!selectedProfile?.profile_status || selectedProfile?.profile_status !== PROFILE_STATUS.VERIFIED) && (
                            <FormControlLabel
                                sx={(theme) => ({
                                    marginRight: 1,
                                    paddingRight: 1,
                                    borderRight: theme.palette.border.primary,
                                })}
                                control={<Switch checked={submitWithError} onChange={(e) => setSubmitWithError(e.target.checked)} />}
                                label="Submit with error"
                            />
                        )}
                        <Box
                            sx={(theme) => ({
                                marginRight: 1,
                                paddingRight: 1,
                                borderRight: theme.palette.border.primary,
                            })}
                        >
                            <Button
                                selected={isUpdatedView}
                                variant="contained"
                                onClick={handleProfileViewChange}
                                sx={(theme) => ({
                                    minWidth: 150,
                                })}
                            >
                                Last Updated Version
                            </Button>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Button
                                selected={isJsonView}
                                variant="contained"
                                onClick={() => setIsJsonView(!isJsonView)}
                                sx={(theme) => ({
                                    minWidth: 150,
                                })}
                            >
                                View as JSON
                            </Button>
                        </Box>
                         <Box
                            sx={(theme) => ({
                                ml: 1,
                                pl: 1,
                                borderLeft: theme.palette.border.primary,
                            })}
                        >
                            <Button
                                variant="contained"
                                onClick={handleOpenDiffViewer}
                                sx={(theme) => ({
                                    minWidth: 150,
                                })}
                            >
                                Open Diff Viewer
                            </Button>
                        </Box>
                        {isUpdatedView && (
                            <Box flexGrow={1} display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
                                <Typography  textAlign="right" color="text.secondary" variant="body2">
                                    {dayjs(lastUpdatedProfile?.updatedAt).format("MMM D, YYYY h:mm A")}{" "}
                                </Typography>
                                <Typography  textAlign="right" color="text.secondary" variant="body2">
                                    Ref: {lastUpdatedProfile?.profile?.ref}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Typography color={"secondary"} variant="body2">
                        {currentFormPath && "You are here" + currentFormPath}
                    </Typography>
                </Stack>
            </Box>
        </>
    );
};

export default CustomFormToolbar;
