import React, { useEffect, useState, useRef } from "react";
import Header from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";
import axios from "axios";
import Papa from "papaparse";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload, Spin, Space, Table, Tag, Typography, Pagination } from "antd";
import { url } from "../../url";
import Select from "react-select";
import { DIRECT_UPDATE_STATUS_OPTIONS } from "./direct-update.utils";
const { Title, Text } = Typography;

const columns = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "UID/Ref",
    dataIndex: "uid",
    key: "uid",
  },
  {
    title: "Key",
    dataIndex: "keyy",
    key: "key",
  },
  {
    title: "Value",
    key: "value",
    dataIndex: "value",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (_, { status }) => {
      let color = "";

      if (status === "pending") {
        color = "yellow";
      } else if (status === "completed") {
        color = "green";
      } else if (status === "error") {
        color = "red";
      } else if (status === "access_denied") {
        color = "purple";
      } else if (status === "exhausted") {
        color = "violet";
      } else {
        color = "blue";
      }

      return <>{<Tag color={color}>{String(status).toUpperCase()}</Tag>}</>;
    },
  },
];

const DirectUpdate = ({ loggedInUserDetails, logoutUser }) => {
  const [csvFile, setCsvFile] = useState();
  const [loader, setLoader] = useState(false);
  const [resultFromUploadCsv, setResultFromUploadCsv] = useState("");
  const [errorFromUploadCsv, setErrorFromUploadCsv] = useState("");
  const [statusData, setStatusData] = useState([]);
  const [selectedStatusValue, setSelectedStatusValue] = useState({ value: "pending", label: "Pending" });
  const [tableDataLoader, setTableDataLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPaginateSize, setTotalPaginateSize] = useState(0);

  useEffect(() => {
    getStatusData();
  }, [selectedStatusValue, page]);

  const getStatusData = async () => {
    setTableDataLoader(true);

    try {
      let response = await axios.get(url.directUpdateStatus + `?page=${page}&status=${selectedStatusValue.value}`);
      response = response.data;
      let temp = [];
      let index = 1;
      console.log("data length: ", response.length);
      for (let data of response.data) {
        temp.push({
          key: String(index),
          type: data.type,
          uid: data.uid ? data.uid : data.ref,
          keyy: data.key,
          value: Array.isArray(data.value) ? data.value.join(", ") : data.value,
          status: data.status,
        });
        index++;
      }
      setTableDataLoader(false);
      setStatusData(temp);
      setTotalPaginateSize(response.total);
    } catch (error) {
      setTableDataLoader(false);
      console.log(error);
    }
  };

  const handleStatusChange = (event) => {
    setSelectedStatusValue(event);
    setPage(0);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page - 1);
  };

  return (
    <div>
      <Header loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
      <TabsHomePage />
      <div className="pe-5 ps-5">
        <div className="mt-4 mb-4 total-data-container">
          <div className="row h-100 w-100">
            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
              {loader ? (
                <Spin tip="Updating profiles, please wait this might take a while..." className="mt-5" />
              ) : (
                <Upload
                  className="mt-5"
                  accept=".csv"
                  beforeUpload={(file) => {
                    const reader = new FileReader();
                    setErrorFromUploadCsv("");
                    reader.onload = (e) => {
                      console.log(e.target.result, e.target.result.length);
                      let csvData = encodeURIComponent(`${loggedInUserDetails._id}\n\r${e.target.result}`);
                      console.log(csvData);
                      setLoader(true);
                      axios
                        .post(url.updateCandidateAttributes, csvData)
                        .then((result) => {
                          setLoader(false);
                          setResultFromUploadCsv(result.data.success);
                          setErrorFromUploadCsv("");
                        })
                        .catch((error) => {
                          console.log(error);
                          setErrorFromUploadCsv(error.response.data.error);
                          console.log(error);
                          setLoader(false);
                          setResultFromUploadCsv("");
                        });
                    };
                    reader.readAsText(file);

                    // prevent upload
                    return false;
                  }}
                  maxCount={1}>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              )}
              {resultFromUploadCsv ? <Text type="success">Successfully uploaded csv</Text> : null}
              {errorFromUploadCsv ? (
                <Text type="danger">
                  {typeof errorFromUploadCsv === "object" ? <code>{JSON.stringify(errorFromUploadCsv, null, 4)}</code> : errorFromUploadCsv}
                </Text>
              ) : null}
            </div>
            <div className="mt-5">
              <Title level={3} style={{ textAlign: "center" }}>
                Status
              </Title>
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }} className="mb-5">
                <Select
                  name="status"
                  options={DIRECT_UPDATE_STATUS_OPTIONS}
                  placeholder={
                    <div className="text-start">
                      Select status&nbsp;<i className="fa-solid fa-filter"></i>
                    </div>
                  }
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={false}
                  onChange={handleStatusChange}
                  value={selectedStatusValue}
                />
              </div>
              <Table
                columns={columns}
                loading={tableDataLoader}
                dataSource={statusData}
                pagination={{
                  position: ["none", "none"],
                  pageSize: 20,
                }}
              />
              <Pagination simple onChange={handlePageChange} current={page + 1} total={totalPaginateSize} defaultPageSize={20} className="my-5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectUpdate;
