import React from "react";
// import "./index.css";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";

import { Card, CardHeader, Divider, IconButton, Typography } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const LanguageForm = ({ fieldPath, name, form }) => {
    return (
        <Card variant="outlined" sx={{ p: 2, mt: 2, backgroundColor: "profileCreatorForm.languages" }}>
            <CardHeader sx={{ p: 0 }} title={<Typography variant="body1">Languages</Typography>} />
            <Divider sx={{ mb: 3 }} />
            <Form.List name={name}>
                {(languageFields, { add: addLanguage, remove: removeLanguage }) => (
                    <>
                        {languageFields.map((languageField) => (
                            <Card variant="outlined" sx={{ p: 2, mt: 2, backgroundColor: "profileCreatorForm.languages" }}>
                                <CardHeader
                                    action={
                                        <IconButton onClick={() => removeLanguage(languageField.name)} aria-label="settings">
                                            <RemoveCircleIcon />
                                        </IconButton>
                                    }
                                    sx={{ p: 0 }}
                                    title={<Typography variant="body1">{`Language ${languageField.name + 1}`}</Typography>}
                                />
                                <Divider sx={{ mb: 3 }} />

                                <Form.Item name={[languageField.name, "name"]} fieldKey={[languageField.fieldKey, "name"]} label="Name">
                                    <Input />
                                </Form.Item>

                                <Form.Item name={[languageField.name, "proficiency"]} label={"Proficiency"}>
                                    <Input />
                                </Form.Item>
                            </Card>
                        ))}

                        <Button type="dashed" onClick={() => addLanguage()} block icon={<PlusOutlined />}>
                            Add Language
                        </Button>
                    </>
                )}
            </Form.List>
        </Card>
    );
};

export default LanguageForm;
