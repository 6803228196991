export const EDITOR_LANGUAGES = {
    javascript: "javascript",
    plaintext: "plaintext",
};

export const parseEditorData = (language, editorData) => {
    switch (language) {
        case EDITOR_LANGUAGES.javascript:
            return JSON.stringify(editorData, null, 2);
        default:
            return editorData;
    }
};
