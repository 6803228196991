import { Chip, TextField } from "@mui/material";
import { useState } from "react";

const ChipAddInput = ({ label, name, values, handleDelete, handleAdd }) => {
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const onDelete = (key, values) => {
        handleDelete(key, values);
    };
    const onAdd = (event) => {
        const response = handleAdd(event);
        if (response?.error) {
            setMessage(response?.message);
            setError(true);
        } else {
            setMessage("");
            setError(false);
        }
    };
    return (
        <TextField
            error={error}
            helperText={message}
            label={label}
            name={name}
            fullWidth
            InputProps={{
                startAdornment: (
                    <>
                        {values.map((phone, index) => (
                            <Chip key={index} label={phone} onDelete={() => onDelete(name, phone)} sx={{ marginRight: 0.5 }} />
                        ))}
                    </>
                ),
            }}
            onKeyDown={onAdd}
            placeholder="Press Enter to add"
        />
    );
};

export default ChipAddInput;
