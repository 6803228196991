import React, { useContext, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Box,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
    useTheme,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
} from "@mui/material";
import { url } from "../../../url";
import axios from "axios";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import MailLockIcon from "@mui/icons-material/MailLock";
import { message } from "antd";
import UserContext from "../../../UserContext";
import { sourceNames } from "./staticVariables";

const OptoutSubmitWithConfirmation = ({ profileInfoSearchData, selectedCustomer }) => {
    const loggedInUserDetails = useContext(UserContext);
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedSource, setSelectedSource] = useState("");
    const [emailsData, setEmailsData] = useState({ isLoading: false, data: null });
    const [extRefsData, setExtRefsData] = useState({ isLoading: false, data: null });
    const [optOutPayload, setOptOutPayload] = useState(profileInfoSearchData);

    const fetchEmailsData = async () => {
        try {
            setEmailsData({ isLoading: true, data: null });
            const response = await axios.post(url.fetchSandboxProfileContacts, {
                source: profileInfoSearchData?.source,
                source_refs: profileInfoSearchData?.source_refs,
                user_id: profileInfoSearchData?.user_id,
            });

            setEmailsData({ isLoading: false, data: response?.data?.data });
        } catch (error) {
            setEmailsData({ isLoading: false, data: [] });
            setOpen(false);
            message.error(error?.response?.data?.data?.message || "Failed to load profile info!");
        }
    };
    const fetchExtRefsData = async () => {
        try {
            setExtRefsData({ isLoading: true, data: null });
            const response = await axios.post(url.fetchSandboxProfilesByContacts, {
                emails: profileInfoSearchData?.emails,
                phones: profileInfoSearchData?.phones,
                user_id: profileInfoSearchData?.user_id,
            });

            const newExtRefsData = response?.data?.data?.reduce((acc, extRef) => {
                if (!acc[extRef.source]) {
                    acc[extRef.source] = [];
                }
                acc[extRef.source].push(extRef.source_ref);
                return acc;
            }, {});

            setExtRefsData({ isLoading: false, data: newExtRefsData });
        } catch (error) {
            setExtRefsData({ isLoading: false, data: null });
            setOpen(false);
            message.error(error?.response?.data?.data?.message || "Failed to load profile info!");
        }
    };

    const handleOpen = async () => {
        setExtRefsData({ data: null });
        setEmailsData({ data: null });
        setSelectedSource("");
        setOptOutPayload(profileInfoSearchData);
        setOpen(true);
        const { user_id, source, source_refs, phones, emails } = profileInfoSearchData;
        if (user_id && source && source_refs.length > 0) {
            await fetchEmailsData();
            return;
        }
        if (user_id && (phones.length > 0 || emails.length > 0)) {
            await fetchExtRefsData();
            return;
        }
    };

    const handleClose = (_, reason) => {
        if (reason !== "backdropClick") {
            setOpen(false);
        }
    };

    const handleConfirm = async () => {
        setLoading(true);
        try {
            const auditLogData = {
                eventName: "optout_client",
                eventType: "compliance",
                userId: loggedInUserDetails._id,
                eventMetaData: {
                    selectedOptOutEmails: optOutPayload?.emails,
                    selectedOptOutSource: optOutPayload?.source,
                    selectedOptOutSourceRefs: optOutPayload?.source_refs,
                    selectedOptOutPhoneNumbers: optOutPayload?.phones,
                    selectedOptOutCountryCode: optOutPayload?.country_code,
                    candidate_ids: optOutPayload?.candidate_ids,
                    optOut: {
                        source: "company",
                        name: selectedCustomer,
                        lookUpBy: {
                            emails: profileInfoSearchData?.emails ?? [],
                            phoneNumbers: profileInfoSearchData?.phones ?? [],
                            countryCode: profileInfoSearchData?.country_code,
                            source: profileInfoSearchData?.source ?? "",
                            sourceRefs: profileInfoSearchData?.source_refs ?? [],
                            userId: profileInfoSearchData?.user_id,
                        },
                        requestType: "deletion",
                    },
                },
            };
            const response = await axios.post(url.clientSpecificOptOut, {
                payload: optOutPayload,
                auditLogData: auditLogData,
            });
            setLoading(false);
            message.success(response?.data?.message || "Successfully opted out for the selected client!");
        } finally {
            setLoading(false); // Stop loading (optional if close is manual)
        }
        handleClose();
    };

    const handleSourceSelect = (event) => {
        setSelectedSource(event.target.value);
        setOptOutPayload({ ...optOutPayload, source: event.target.value });
    };

    const isSearchDisabled = () => {
        const { user_id, source, source_refs, phones, emails } = profileInfoSearchData;
        if (!user_id) {
            return true;
        }
        if (user_id && source && source_refs.length > 0) {
            return false;
        }
        if (user_id && (phones.length > 0 || emails.length > 0)) {
            return false;
        }
        return true;
    };

    const isConfirmDisabled = () => {
        const { user_id, source, source_refs, phones, emails } = optOutPayload;
        if (!user_id) {
            return true;
        }
        return false;
    };

    const handleEmailsChange = (event, checked) => {
        const newEmail = event.target.value;
        if (checked) {
            setOptOutPayload({ ...optOutPayload, emails: [...optOutPayload.emails, newEmail] });
        } else {
            setOptOutPayload({ ...optOutPayload, emails: optOutPayload.emails.filter((email) => email !== newEmail) });
        }
    };
    const handleSourceRefsChange = (event, checked) => {
        const newSourceRef = event.target.value;
        if (checked) {
            setOptOutPayload({ ...optOutPayload, source_refs: [...optOutPayload.source_refs, newSourceRef] });
        } else {
            setOptOutPayload({
                ...optOutPayload,
                source_refs: optOutPayload.source_refs.filter((sourceRef) => sourceRef !== newSourceRef),
            });
        }
    };

    return (
        <>
            <Button disabled={isSearchDisabled()} fullWidth variant="contained" onClick={handleOpen}>
                Search Profiles
            </Button>

            {/* Confirmation Modal */}
            <Dialog open={open} onClose={handleClose}>
                {(emailsData?.data || extRefsData?.data) && (
                    <>
                        <DialogTitle>Confirm Submission</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Are you sure you want to opt-out?</DialogContentText>
                            {emailsData?.data && (
                                <Box>
                                    <Box>
                                        <Typography variant="body1" display="flex" alignItems="center" gap={"0.5rem"} mt="1rem">
                                            <EmailIcon />
                                            Emails
                                        </Typography>
                                        <FormGroup>
                                            {emailsData?.data?.emails?.length > 0 &&
                                                emailsData?.data?.emails?.map((email, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={email + index}
                                                            sx={{ ml: theme.spacing(2.5) }}
                                                            onChange={handleEmailsChange}
                                                            control={<Checkbox value={email} size="small" />}
                                                            label={<Typography variant="body2">{email}</Typography>}
                                                        />
                                                    );
                                                })}
                                        </FormGroup>
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" display="flex" alignItems="center" gap={"0.5rem"} mt="1rem">
                                            <MailLockIcon />
                                            Opted-Out Emails
                                        </Typography>
                                        {emailsData?.data?.opted_out_emails?.length > 0 &&
                                            emailsData?.data?.opted_out_emails?.map((email) => {
                                                return (
                                                    <Typography
                                                        key={email}
                                                        sx={{ ml: theme.spacing(4.5) }}
                                                        color={"text.secondary"}
                                                        variant="body2"
                                                    >
                                                        {email}
                                                    </Typography>
                                                );
                                            })}
                                    </Box>
                                </Box>
                            )}
                            {extRefsData?.data && (
                                <Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: theme.spacing(38),
                                        }}
                                    >
                                        <Typography variant="body1" display="flex" alignItems="center" gap={"0.5rem"} mt="1rem">
                                            <LanguageIcon />
                                            Ext Refs
                                        </Typography>
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel>Source</InputLabel>
                                            <Select value={selectedSource} onChange={handleSourceSelect} label="Source">
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {Object.keys(extRefsData?.data).length > 0 &&
                                                    Object.keys(extRefsData?.data)
                                                        .filter((source) => sourceNames.includes(source))
                                                        .map((source) => {
                                                            return (
                                                                <MenuItem key={source} value={source}>
                                                                    {source}
                                                                </MenuItem>
                                                            );
                                                        })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <FormGroup>
                                        {extRefsData?.data?.[selectedSource] &&
                                            extRefsData?.data?.[selectedSource].map((sourceRef, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={sourceRef + index}
                                                        onChange={handleSourceRefsChange}
                                                        value={sourceRef}
                                                        sx={{ ml: theme.spacing(2.5) }}
                                                        control={<Checkbox size="small" />}
                                                        label={<Typography variant="body2">{sourceRef}</Typography>}
                                                    />
                                                );
                                            })}
                                    </FormGroup>
                                </Box>
                            )}
                        </DialogContent>
                        <DialogActions>
                            {!loading && (
                                <Button onClick={handleClose} color="primary">
                                    Cancel
                                </Button>
                            )}
                            <Button onClick={handleConfirm} color="warning" disabled={isConfirmDisabled()}>
                                {loading ? <CircularProgress size={20} /> : "Confirm"}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default OptoutSubmitWithConfirmation;
