import { useLocation } from 'react-router-dom';

export const useFeatureFlags = (queryParamName = 'features') => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const featuresParam = params.get(queryParamName) || '';
  const enabledFeatures = featuresParam.split(',').map(f => f.trim());

  const flagsStatus = {};

  enabledFeatures.forEach((flagName) => {
    flagsStatus[flagName] = enabledFeatures.includes(flagName);
  });

  return flagsStatus;
};