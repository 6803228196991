import dayjs from "dayjs";

export const extractLinkedInId = (url) => {
    const regex = /\/in\/([^/]+)/;
    const match = url.match(regex);
    if (match && match[1]) {
        return match[1];
    }
    return null;
};

export const sortJsonObject = (obj) => {
    if (dayjs.isDayjs(obj)) {
        return obj;
    }
    if (Array.isArray(obj)) {
        // If the object is an array, recursively sort its elements
        return obj.map(sortJsonObject);
    } else if (typeof obj === "object" && obj !== null) {
        // If the object is an actual object, sort its keys
        return Object.keys(obj)
            .sort() // Sort keys alphabetically
            .reduce((sortedObj, key) => {
                // Build a new object with sorted keys
                sortedObj[key] = sortJsonObject(obj[key]); // Recursively sort nested objects
                return sortedObj;
            }, {});
    }

    // If it's a primitive (string, number, etc.), return it as is
    return obj;
};

export const isValidLinkedinUrl = (url) => {
    const linkedinRegex = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/;

    return linkedinRegex.test(url);
};

export const ICPType = {
    UserDefined: 0,
    Rediscovery: 1, // This is automatic created ICPs will not have metadata.
    Temporary: 2, // Temporary ICP created for analytics etc.
    Job: 3, // icp for jobs
    SelfService: 4, // icp for jobs
    SourcingAccelerator: 5, // SA Internal jobs
    Preprocessing: 6, // Preprocessing ICP
    ExecMap: 7, // ExecMap ICP
};
