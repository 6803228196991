import dayjs from "dayjs";

export function transformObject(obj) {
 
    // Check if the input is an object
    if (typeof obj !== "object" || obj === null) {
        return obj;
    }

    // If the object is a Date instance, return it as is
    if (dayjs.isDayjs(obj)) {
        return obj;
    }

    // Function to handle arrays of strings
    const handleArray = (arr, key) => {
        return arr.map((item) => ({ [key]: item }));
    };

    // Recursively transform the object
    const transformed = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            // Transform if it's an array of strings and not empty
            if (obj[key].length > 0 && typeof obj[key][0] === "string") {
                transformed[key] = handleArray(obj[key], key);
            } else {
                // If it's not an array of strings or it's empty, keep as is
                transformed[key] = obj[key].map((item) => transformObject(item));
            }
        } else if (dateSpecificFields.includes(key)) {
            transformed[key] = dayjs(obj[key]).isValid() ? dayjs(obj[key]) : null;
        } else {
            // For non-array values, recursively call the function
            transformed[key] = transformObject(obj[key]);
        }
    }
    return transformed;
}

export function reverseTransformObject(obj) {
    // Check if the input is an object

    
    if (typeof obj !== "object" || obj === null) {
        return obj;
    }

    // If the object is a Date instance, return it as is
    if (dayjs.isDayjs(obj)) {
        return obj;
    }

    // Function to handle arrays of objects
    const handleArray = (arr, key) => {
        return arr.map((item) => {
            // Check if item is not null and has the required key
            return item && item[key] ? item[key] : null;
        });
    };

    // Recursively reverse transform the object
    const reversed = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            // Check if it's an array of objects with a specific key
            if (obj[key].length > 0 && typeof obj[key][0] === "object" && obj[key][0] !== null && obj[key][0][key]) {
                reversed[key] = handleArray(obj[key], key);
            } else {
                // Process each item in the array
                reversed[key] = obj[key].map((item) => reverseTransformObject(item));
            }
        } else {
            // For non-array values, recursively call the function
            reversed[key] = reverseTransformObject(obj[key]);
        }
    }
    return reversed;
}

const dateSpecificFields = ["created_at", "updated_at", "start_date", "end_date", "date"];
