import { Box, Container, Paper, useTheme } from "@mui/material";
import LeftPane from "./LeftPane";
import RightPane from "./RightPane";
import { useState } from "react";

const InstantProfileRefreshRequest = () => {
    const [uids, setUids] = useState("");

    //styling
    const theme = useTheme();
    const heights = theme.custom.heights;

    return (
        <Paper
            sx={(theme) => ({ padding: "0 3rem", height: `calc(100vh - ${heights.header + heights.navbar + heights.subTabs}px)` })}
            elevation={0}
        >
            <Paper sx={{ display: "flex", maxWidth: "100%", height: "100%" }}>
                <LeftPane uids={uids} setUids={setUids}></LeftPane>
                <RightPane uids={uids}></RightPane>
            </Paper>
        </Paper>
    );
};

export default InstantProfileRefreshRequest;
