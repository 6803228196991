import { Box, IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import { message } from "antd";
import axios from "axios";
import { url } from "../../../url";
import GroupIcon from "@mui/icons-material/Group";
import InputCard from "./InputCard";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
const UserGroup = ({ group, setUserGroups, userGroups }) => {
    const [moreOpen, setMoreOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMoreClick = (event) => {
        setMoreOpen(true);
        setAnchorEl(event.currentTarget);
    };
    const handleMoreClose = () => {
        setMoreOpen(false);
        setAnchorEl(null);
    };
    const removeUserGroup = async (event) => {
        const groupId = event.currentTarget.dataset.groupId;
        if (!groupId) {
            return;
        }
        try {
            const response = await axios.delete(url.crudUserGroup, {
                params: {
                    groupId: groupId,
                },
            });
            const newUserGroups = userGroups?.data?.filter((group) => groupId != group._id);
            setUserGroups({ data: newUserGroups, isLoading: false });
            message.info("User Group deleted successfully!");
        } catch (error) {
            console.log("Failed to delete user group:", error);
            message.error("Failed to delet user group!");
        }
    };

    const addUser = async (email, groupId) => {
        try {
            const response = await axios.post(url.crudUserOfUserGroup, {
                groupId: groupId,
                email: email,
            });
            // console.log(response);
            const newUserGroups = userGroups?.data?.map((group) => {
                if (group._id == groupId) {
                    const newUsers = [...group?.users, response?.data?.data];
                    group.users = newUsers;
                    return group;
                }
                return group;
            });
            setUserGroups({ ...userGroups, data: [...newUserGroups] });
        } catch (error) {
            console.log("Failed to add new user:", error);
            if (error?.response?.status) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to add new user!");
        }
    };

    const removeUser = async (event) => {
        try {
            const { groupId, email } = event.currentTarget.dataset;
            console.log(groupId);
            const response = await axios.delete(url.crudUserOfUserGroup, {
                params: {
                    groupId: groupId,
                    email: email,
                },
            });
            const newUserGroups = userGroups?.data?.map((group) => {
                if (group._id == groupId) {
                    return response?.data?.data;
                }
                return group;
            });
            setUserGroups({ ...userGroups, data: [...newUserGroups] });
        } catch (error) {
            console.log("Failed to remove user:", error);
            if (error?.response?.status) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to remove user!");
        }
    };
    const open = Boolean(anchorEl);

    return (
        <List
            key={group._id}
            sx={{ width: "350px", background: "#F4F5F7", borderRadius: "12px", overflow: "hidden" }}
            subheader={
                <ListSubheader sx={{ background: "#F4F5F7" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <GroupIcon sx={{ mr: "0.5rem" }} />
                            {group.name}
                        </div>
                        <IconButton onClick={handleMoreClick}>
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            open={moreOpen}
                            anchorEl={anchorEl}
                            onClose={handleMoreClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <MenuItem data-group-id={group._id} onClick={removeUserGroup}>
                                <ListItemIcon>
                                    <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                            </MenuItem>
                        </Menu>
                    </Box>
                </ListSubheader>
            }
        >
            {group.users.map((user) => {
                return (
                    <ListItem
                        secondaryAction={
                            <Tooltip title="Remove User">
                                <IconButton edge="end" data-group-id={group._id} data-email={user.email} onClick={removeUser}>
                                    <RemoveCircleOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    >
                        {user.name}
                    </ListItem>
                );
            })}
            <InputCard label={"Add user"} inputLabel={"Email"} onSubmit={addUser} groupId={group._id} />
        </List>
    );
};
export default UserGroup;
