import { DiffEditor } from "@monaco-editor/react";
import { useTheme } from "@mui/material";
import React from "react";
import { sortJsonObject } from "../../utils/util";

const DiffDataEditor = ({ originalData, modifiedData, language = "javascript" }) => {
    const originalValue = JSON.stringify(sortJsonObject(originalData), null, 2);
    const modifiedValue = JSON.stringify(sortJsonObject(modifiedData), null, 2);
    const theme = useTheme();

    return (
        <DiffEditor
            language={language}
            theme={theme.palette.monacoTheme}
            original={originalValue}
            modified={modifiedValue}
            options={{
                readOnly: true,
                renderSideBySide: true,
                automaticLayout: true,
            }}
        />
    );
};

export default DiffDataEditor;
