import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";
import { Switch } from "antd";
import { Tabs } from "antd";
import ScrapingError from "./ScrapingError";
import ParsingError from "./ParsingError";

import "./LocationHeuristics.styles.css";

const LocationHeuristics = ({ loggedInUserDetails, logoutUser }) => {
  const [showTaskAssignedTo, setShowTaskAssignedTo] = useState(true);

  const onShowTaskAssignedToCheckedChange = (checked) => {
    if (checked) {
      setShowTaskAssignedTo(true);
    } else {
      setShowTaskAssignedTo(false);
    }
  };

  return (
    <div>
      <Header loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
      {loggedInUserDetails.role === "admin" ? (
        <h6 className="text-center">
          <Switch
            checkedChildren="Show all"
            unCheckedChildren="Show only assigned"
            className="mx-5"
            defaultChecked
            onChange={onShowTaskAssignedToCheckedChange}
          />
        </h6>
      ) : null}
      <TabsHomePage />
      <div className="pe-5 ps-5">
        <Tabs type="card" centered className="mt-2">
          <Tabs.TabPane tab="Scraping Errors" key="tab1">
            <ScrapingError loggedInUserDetails={loggedInUserDetails} showTaskAssignedTo={showTaskAssignedTo} />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Parsing Errors" key="tab2">
            <ParsingError loggedInUserDetails={loggedInUserDetails} showTaskAssignedTo={showTaskAssignedTo} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default LocationHeuristics;
