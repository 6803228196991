import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from "@mui/material";
import { LEADERSHIP_CONST } from "../../../utils/constants";

const { PROFILE_STATUS } = LEADERSHIP_CONST;
const SubmitButtonWithConfirmation = ({ selectedProfile, selectedMappedProfile, updateMappedProfile }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = (_, reason) => {
        if (reason !== "backdropClick") {
            setOpen(false);
        }
    };

    const handleConfirm = async () => {
        setLoading(true);
        try {
            await updateMappedProfile();
        } finally {
            setLoading(false); // Stop loading (optional if close is manual)
        }
        handleClose();
    };

    return (
        <>
            <Button
                disabled={!selectedMappedProfile}
                variant="outlined"
                onClick={handleOpen}
                sx={{
                    borderRadius: "3px",
                    color: "text.primary",
                    fontWeight: "bold",
                    textTransform: "none",
                    width: "80%",
                    margin: "1rem 2rem",
                }}
            >
                {selectedProfile?.profile_status && selectedProfile?.profile_status === PROFILE_STATUS.VERIFIED
                    ? "Update Mapping"
                    : "Submit Selected Mapping"}
            </Button>

            {/* Confirmation Modal */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Submission</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to submit this mapping?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!loading && (
                        <Button onClick={handleClose} color="secondary">
                            Cancel
                        </Button>
                    )}
                    <Button onClick={handleConfirm} color="primary" disabled={loading}>
                        {loading ? <CircularProgress size={20} /> : "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SubmitButtonWithConfirmation;
