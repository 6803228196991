import React, { useEffect, useState } from "react";
// import "./index.css";
import _ from "lodash";
import { Form, Input, Tabs, Spin, Modal, Select, DatePicker } from "antd";

import { sources } from "./staticVariables";
import WorkExpForm from "./formComponents/WorkExpForm";
import EducationForm from "./formComponents/EducationForm";
import CandidateForm from "./formComponents/CandidateForm";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { reverseTransformObject, transformObject } from "./utils";
import SkillsForm from "./formComponents/SkillsForm";
import AccoladesForm from "./formComponents/AccoladesForm";
import { Box, Button, Card, CardHeader, Divider, IconButton, Typography } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useTheme } from "@emotion/react";
import AdditionalInfoForm from "./formComponents/AdditionalInfoForm";

const ProfileCreatorForm = ({
    form,
    profileData,
    handlePostCreatedProfile,
    readOnlyView,
    isUpdate,
    getCurrentFieldPath,
    submitDisabled,
}) => {
    const [isFinalModalOpen, setIsFinalModalOpen] = useState(false);
    const [createdProfile, setCreatedProfile] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [initialFormData, setInitialFormData] = useState();
    const theme = useTheme();

    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

    const sourceOptions = sources.map((source) => ({
        label: source,
        value: source,
    }));

    const onFinish = (values) => {
        setCreatedProfile(reverseTransformObject(values));
        console.log(reverseTransformObject(values));
        setIsFinalModalOpen(true);
    };

    const handleSubmit = () => {
        form.submit();
    };

    const onConfirm = () => {
        handlePostCreatedProfile(createdProfile);
        setIsFinalModalOpen(false);
    };

    const handleFinalModalCancel = () => {
        setIsFinalModalOpen(false);
    };

    const onReset = () => {
        form.resetFields();
        form.setFieldsValue(initialFormData);
    };

    useEffect(() => {
        const newInitialFormData = transformObject(profileData);
        form.resetFields();
        setInitialFormData(newInitialFormData);
        form.setFieldsValue(newInitialFormData);
    }, [profileData]);

    return (
        <>
            <Form
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 18,
                }}
                form={form}
                name="dynamic_form_complex"
                style={{
                    width: "100%",
                    margin: "1rem",
                    backgroundColor: theme.palette.background.paper,
                }}
                onFinish={onFinish}
                autoComplete="off"
                scrollToFirstError
                disabled={readOnlyView}
                onFieldsChange={getCurrentFieldPath}
            >
                <Box
                    sx={(theme) => ({
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        bgcolor: "background.paper",
                    })}
                >
                    <Button
                        onClick={onReset}
                        variant="contained"
                        style={{
                            width: "90px",
                        }}
                    >
                        <RestartAltIcon style={{ fontSize: "1rem" }} /> <span>Reset</span>
                    </Button>

                    <h4
                        style={{
                            textAlign: "center",
                            marginTop: "1rem",
                            height: "50px",
                            fontSize: "40px",
                            fontWeight: 600,
                            backgroundImage: "linear-gradient(to left, #553c9a, #b393d3)",
                            color: "transparent",
                            backgroundClip: "text",
                            WebkitBackgroundClip: "text",
                        }}
                    >
                        {readOnlyView ? "Read Only Profile" : isUpdate ? "Update Profile" : "Create Profile"}
                    </h4>

                    <Form.Item>
                        <Button
                            onClick={handleSubmit}
                            disabled={submitDisabled}
                            style={{
                                marginTop: "1.5rem",
                                boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                            }}
                        >
                            {isUpdate ? "Update" : "Submit"}
                        </Button>
                    </Form.Item>
                </Box>

                <div>
                    <Tabs type="card" centered className="mt-2">
                        <Tabs.TabPane tab="Other Fields" key="tab1">
                            <div
                                style={{
                                    display: "flex",
                                    rowGap: 16,
                                    flexDirection: "column",
                                    // border: "2px solid red",
                                    marginBottom: "1rem",
                                    padding: "1rem",
                                    borderRadius: "10px",
                                    //   boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                                }}
                            >
                                <Form.Item key={"ext_src"} label={"ext_src"} name={["ext_src"]}>
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item key={"ext_src_ref"} label={"ext_src_ref"} name={["ext_src_ref"]}>
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item key={"created_at"} label={"created_at"} name={["created_at"]}>
                                    <DatePicker disabled placeholder={`Select created_at`} />
                                </Form.Item>
                                <Form.Item key={"updated_at"} label={"updated_at"} name={["updated_at"]}>
                                    <DatePicker disabled placeholder={`Select updated_at`} />
                                </Form.Item>
                            </div>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab="Candidate" key="tab2" forceRender>
                            {/* for candidate */}
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: 16,
                                    marginBottom: "1rem",
                                    padding: "1rem",
                                    borderRadius: "10px",
                                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                }}
                            >
                                <CandidateForm form={form} />
                            </div>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab="Skills" key="tab3" forceRender>
                            {/* for skills */}
                            <div
                                style={{
                                    display: "flex",
                                    // rowGap: 16,
                                    flexDirection: "column",
                                    marginBottom: "1rem",
                                    padding: "1rem",
                                    borderRadius: "10px",
                                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                }}
                            >
                                <SkillsForm fieldPath={["skills"]} name={["skills"]} form={form} />
                            </div>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab="Workexp" key="tab4" forceRender>
                            {/* for workexp */}
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: 16,
                                    marginBottom: "1rem",
                                    padding: "1rem",
                                    borderRadius: "10px",
                                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                }}
                            >
                                <WorkExpForm form={form} />
                            </div>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab="Ext Refs" key="tab5" forceRender>
                            {/* for ext_refs */}
                            <Form.List name="ext_refs">
                                {(fields, { add, remove }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            rowGap: 16,
                                            flexDirection: "column",
                                            marginBottom: "1rem",
                                            padding: "1rem",
                                            borderRadius: "10px",
                                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                        }}
                                    >
                                        {fields.map((field) => (
                                            <Card
                                                key={field.key}
                                                variant="outlined"
                                                sx={{ p: 2, backgroundColor: "profileCreatorForm.main" }}
                                            >
                                                <CardHeader
                                                    action={
                                                        <IconButton
                                                            onClick={() => {
                                                                remove(field.name);
                                                            }}
                                                            aria-label="settings"
                                                        >
                                                            <RemoveCircleIcon />
                                                        </IconButton>
                                                    }
                                                    sx={{ p: 0 }}
                                                    title={<Typography variant="body1">{`Ext Ref ${field.name + 1}`}</Typography>}
                                                />
                                                <Divider sx={{ mb: 3 }} />

                                                <>
                                                    <Form.Item label="Source" name={[field.name, "source"]}>
                                                        <Select placeholder="Select a source" options={sourceOptions} />
                                                    </Form.Item>

                                                    <Form.Item label="Source Ref" name={[field.name, "source_ref"]}>
                                                        <Input placeholder="Source Ref" />
                                                    </Form.Item>

                                                    <Form.Item
                                                        label="URL"
                                                        name={[field.name, "url"]}
                                                        rules={[
                                                            {
                                                                validator: (_, value) => {
                                                                    if (!value) {
                                                                        // If the field is empty, no validation is required
                                                                        return Promise.resolve();
                                                                    }
                                                                    if (urlPattern.test(value)) {
                                                                        // If it's a valid URL, resolve the promise
                                                                        return Promise.resolve();
                                                                    }
                                                                    // If it's not a valid URL, reject the promise with an error message
                                                                    return Promise.reject("Please enter a valid URL");
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="URL" />
                                                    </Form.Item>
                                                </>
                                            </Card>
                                        ))}

                                        <Button onClick={() => add()}>+ Add Ext Refs</Button>
                                    </div>
                                )}
                            </Form.List>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Educations" key="tab7" forceRender>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: 16,
                                    marginBottom: "1rem",
                                    padding: "1rem",
                                    borderRadius: "10px",
                                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                }}
                            >
                                <EducationForm form={form} />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Accolades" key="tab6">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: 16,
                                    marginBottom: "1rem",
                                    padding: "1rem",
                                    borderRadius: "10px",
                                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                }}
                            >
                                <AccoladesForm fieldPath={[]} name={["accolades"]} form={form} />
                            </div>
                        </Tabs.TabPane>

                        {/* <Tabs.TabPane tab="Compensation History" key="tab10" forceRender>
                            Compensation History
                            <Card title="Compensation History" size="small">
                                <Form.List name="compensation_history">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, fieldKey }) => (
                                                <Card
                                                    size="small"
                                                    extra={
                                                        <MinusCircleOutlined onClick={() => !disableMinusCircleOutlined && remove(name)} />
                                                    }
                                                    title={`Compensation Hisory ${fieldKey + 1}`}
                                                    key={key}
                                                    align="baseline"
                                                >
                                                    <Form.Item
                                                        name={[name, "est_compensation"]}
                                                        fieldKey={[fieldKey, "est_compensation"]}
                                                        label="Estimated Compensation"
                                                    >
                                                        <Input disabled={true} placeholder="Estimated Compensation" />
                                                    </Form.Item>
                                                    <Form.Item name={[name, "date"]} fieldKey={[fieldKey, "date"]} label="Date">
                                                        <Input disabled={true} placeholder="Date" />
                                                    </Form.Item>
                                                </Card>
                                            ))}
                                            <Button
                                                type="dashed"
                                                onClick={() => !disableMinusCircleOutlined && add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Add Compensation Entry
                                            </Button>
                                        </>
                                    )}
                                </Form.List>
                            </Card>
                        </Tabs.TabPane> */}

                        {/* <Tabs.TabPane tab="Dedup History" key="tab11" forceRender>
                            Dedupe History
                            <Card title="Dedupe History" size="small">
                                <Form.List name="dedupe_history">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, fieldKey }) => (
                                                <Card
                                                    key={key}
                                                    size="small"
                                                    title={`Dedup Hitory ${fieldKey + 1}`}
                                                    extra={
                                                        <MinusCircleOutlined onClick={() => !disableMinusCircleOutlined && remove(name)} />
                                                    }
                                                >
                                                    <Form.Item
                                                        name={[name, "dupl_prid"]}
                                                        fieldKey={[fieldKey, "dupl_prid"]}
                                                        label="Duplicate PRID"
                                                    >
                                                        <Input disabled={true} placeholder="Duplicate PRID" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[name, "dedupe_ts"]}
                                                        fieldKey={[fieldKey, "dedupe_ts"]}
                                                        label="Dedupe Timestamp"
                                                    >
                                                        <Input disabled={true} placeholder="Dedupe Timestamp" />
                                                    </Form.Item>
                                                </Card>
                                            ))}
                                            <Button
                                                type="dashed"
                                                onClick={() => !disableMinusCircleOutlined && add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Add Dedupe History
                                            </Button>
                                        </>
                                    )}
                                </Form.List>
                            </Card>
                        </Tabs.TabPane> */}

                        {/* <Tabs.TabPane tab="Manual Update History" key="tab12" forceRender>
                            Manual Update History
                            <Card title="Manual Update History" size="small">
                                <Form.List name="manual_update_history">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, fieldKey }) => (
                                                <Card
                                                    extra={
                                                        <MinusCircleOutlined onClick={() => !disableMinusCircleOutlined && remove(name)} />
                                                    }
                                                    size="small"
                                                    title={`Manual Update History ${fieldKey + 1}`}
                                                    key={key}
                                                    align="baseline"
                                                >
                                                    <Form.Item name={[name, "field"]} fieldKey={[fieldKey, "field"]} label="Field">
                                                        <Input disabled={true} placeholder="Field" />
                                                    </Form.Item>
                                                    <Form.Item name={[name, "value"]} fieldKey={[fieldKey, "value"]} label="Value">
                                                        <Input disabled={true} placeholder="Value" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[name, "updated_at"]}
                                                        fieldKey={[fieldKey, "updated_at"]}
                                                        label="Updated At"
                                                    >
                                                        <Input disabled={true} />
                                                    </Form.Item>
                                                </Card>
                                            ))}
                                            <Button
                                                type="dashed"
                                                onClick={() => !disableMinusCircleOutlined && add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Add Manual Update
                                            </Button>
                                        </>
                                    )}
                                </Form.List>
                            </Card>
                        </Tabs.TabPane> */}

                        {/*<Tabs.TabPane tab="Published Work" key="tab8">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Coming Soon
            </div>
          </Tabs.TabPane>*/}

                        <Tabs.TabPane tab="Additional Info" key="tab9" forceRender>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: 16,
                                    marginBottom: "1rem",
                                    padding: "1rem",
                                    borderRadius: "10px",
                                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                }}
                            >
                                <AdditionalInfoForm fieldPath={["additional_info"]} name={["additional_info"]} form={form} />
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
                {/* MODAL */}
                <>
                    <Modal
                        style={{ marginTop: "14rem" }}
                        title="Confirm?"
                        visible={isFinalModalOpen}
                        onOk={onConfirm}
                        onCancel={handleFinalModalCancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        {isCreating ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Spin />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>Submitting! Please wait...</span>
                                </div>
                            </>
                        ) : (
                            <p>Do you agree to submit?</p>
                        )}
                    </Modal>
                </>
            </Form>
        </>
    );
};
export default ProfileCreatorForm;
