import React from "react";
import { PlusOutlined, MinusCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space, InputNumber, Tabs, Spin, Modal, message, Select, DatePicker } from "antd";
import { Card, CardHeader, Divider, Typography } from "@mui/material";

//fieldPath -> complete path e.g. ["workexp", "idx", "projects"]
//name -> only required path for antd form for e.g. ["idx", "projects"]
//form -> form instance of antd
const SkillsForm = ({ fieldPath, name, form }) => {
    return (
        <Card variant="outlined" sx={{ p: 2, mt: 2, backgroundColor: "profileCreatorForm.main" }}>
            <CardHeader sx={{ p: 0 }} title={<Typography variant="body1">Skills</Typography>} />

            <Divider sx={{ mb: 3 }} />
            <Form.List name={name}>
                {(fields, { add, remove }) => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        {fields.map(({ key, name }) => (
                            <Space key={key} align="baseline">
                                <Form.Item name={[name, "skills"]}>
                                    <Input
                                        style={{ width: "250px", margin: "0 5px 0 5px" }}
                                        placeholder={`Skill ${key + 1}`}
                                        addonAfter={<MinusCircleOutlined onClick={() => remove(name)} />}
                                    />
                                </Form.Item>
                            </Space>
                        ))}
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Skill
                        </Button>
                    </div>
                )}
            </Form.List>
        </Card>
    );
};
export default SkillsForm;
