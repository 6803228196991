import {
    Autocomplete,
    Box,
    Checkbox,
    Divider,
    TextField,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
} from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { url } from "../../../url";
import { message } from "antd";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { debounce } from "lodash";
import { AUDIT_LOG_EVENT_NAMES, LEADERSHIP_CONST } from "../../../utils/constants";
import CompletedTasksCard from "../../Common/cards/CompletedTasksCard";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const { PROFILE_STATUS } = LEADERSHIP_CONST;

const FilterToolbar = ({ filterQuery, setFilterQuery, tasksCompletedForToday }) => {
    const [companyFilterOptions, setCompanyFilterOptions] = useState({ isLoading: false, data: [] });
    const [companyFilterPage, setCompanyFilterPage] = useState(-1);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedProfileStatus, setSelectedProfileStatus] = useState([]);
    const [companyFilterInput, setCompanyFilterInput] = useState("");
    const [hasMore, setHasMore] = useState(true);
    const fetchCompanyFilterOptions = useCallback(
        debounce(async (reset, companyName) => {
            try {
                setCompanyFilterOptions((prev) => ({ ...prev, isLoading: true }));
                const response = await axios.get(url.leadershipProfile.getCompanyFilterOptions, {
                    params: {
                        companyName: companyName,
                        page: reset ? 0 : companyFilterPage + 1,
                    },
                });

                if (response?.data?.data?.length > 0) {
                    setCompanyFilterPage(reset ? 0 : companyFilterPage + 1);
                    setCompanyFilterOptions((prev) => ({
                        data: reset ? response.data.data : [...prev.data, ...response.data.data],
                        isLoading: false,
                    }));
                } else {
                    setHasMore(false);
                    setCompanyFilterOptions((prev) => ({ ...prev, isLoading: false }));
                }
            } catch (error) {
                setCompanyFilterOptions((prev) => ({ ...prev, isLoading: false }));
                console.error("Failed to fetch filter options:", error);
                if (error?.response?.data?.message) {
                    message.error(error?.response?.data?.message);
                }
                message.error("Failed to fetch filter options!");
            }
        }, 300),
        [companyFilterPage]
    );

    const handleCompanyLinksChange = (event, newValues) => {
        setFilterQuery((prevFilterQuery) => {
            if (newValues.length === 0) {
                const { company_link, ...rest } = prevFilterQuery;
                return rest;
            }
            return {
                ...prevFilterQuery,
                company_link: newValues.map((item) => item.value),
            };
        });
        setSelectedOptions(newValues);
    };

    const groupedOptions = [
        ...selectedOptions,
        ...(companyFilterOptions?.data || []).filter((option) => !selectedOptions.some((selected) => selected.value === option.value)),
    ];

    const handleCompanyFilterScroll = (event) => {
        if (hasMore) {
            const listboxNode = event.currentTarget;
            if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 50) {
                fetchCompanyFilterOptions(false, companyFilterInput);
            }
        }
    };

    const handleCompanyFilterInputChange = (event, value) => {
        setCompanyFilterInput(value);
        setHasMore(true);
        fetchCompanyFilterOptions(true, value);
    };
    const handleProfileStatusChange = (event) => {
        const newValues = event.target.value;
        setSelectedProfileStatus(newValues);
        setFilterQuery((prevFilterQuery) => {
            if (newValues.length === 0) {
                const { profile_status, ...rest } = prevFilterQuery;
                return rest;
            }
            return {
                ...prevFilterQuery,
                profile_status: newValues.map((item) => item),
            };
        });
        console.log(newValues);
    };

    useEffect(() => {
        fetchCompanyFilterOptions();
    }, []);

    return (
        <>
            <Box
                sx={(theme) => ({
                    position: "sticky",
                    top: theme.custom.top.level2,
                    zIndex: theme.zIndex.navbar,
                    height: theme.custom.heights.filterbar,
                    boxSizing: "border-box",
                    pl: 3,
                    pr: 3,
                    borderBlockEnd: theme.palette.border.primary,
                    backgroundColor:theme.palette.background.default
                })}
            >
                <Box
                    sx={(theme) => ({
                        padding: "",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        pt: 1,
                        pb: 1,
                    })}
                >
                    <Box display={"flex"} alignItems={"center"}>
                        <Autocomplete
                            sx={{ minWidth: "20rem", pr: "1rem", borderRight: "1px solid grey"}}
                            multiple
                            onChange={handleCompanyLinksChange}
                            options={groupedOptions}
                            getOptionLabel={(option) => option?.label || ""}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderTags={(value, getTagProps) => {
                                return [`${value.length} selected`]; // Show the count of selected tags
                            }}
                            onInputChange={handleCompanyFilterInputChange}
                            ListboxProps={{
                                onScroll: handleCompanyFilterScroll,
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Companies"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {companyFilterOptions.isLoading ? <CircularProgress size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            renderOption={(props, option, { selected }) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <li key={key} {...optionProps}>
                                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                        {option.label}
                                    </li>
                                );
                            }}
                        />
                        <FormControl sx={{ minWidth: "10rem", ml: "1rem"}}>
                            <InputLabel id="profile-status-filter">Profile Status</InputLabel>
                            <Select
                                multiple
                                labelId="profile-status-filter"
                                value={selectedProfileStatus}
                                label="Profile Status"
                                onChange={handleProfileStatusChange}
                            >
                                <MenuItem value={PROFILE_STATUS.UNPROCESSED}>Unverified</MenuItem>
                                <MenuItem value={PROFILE_STATUS.VERIFIED}>Verified</MenuItem>
                                <MenuItem value={PROFILE_STATUS.ERRORED}>Errored</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <CompletedTasksCard tasksCompletedForToday={tasksCompletedForToday} />
                </Box>
            </Box>
        </>
    );
};

export default FilterToolbar;
