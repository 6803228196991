import { Box, Tab, Tabs } from "@mui/material";
import { Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from "../../../UserContext";
import { checkPermission, DRA_MODULES, MODULE_ACTIONS, usePermission } from "../../utils/rbacUtils";

const tabItems = [
    {
        value: 1,
        label: "Your Profile",
        pathname: "/user-dashboard/profile",
    },
    {
        value: 2,
        label: "Users",
        permissionRequired: true,
        module: DRA_MODULES.users,
        pathname: "/user-dashboard/users",
    },
    {
        value: 3,
        label: "Roles",
        permissionRequired: true,
        module: DRA_MODULES.roles,
        pathname: "/user-dashboard/roles",
    },
    {
        value: 4,
        label: "Admin",
        permissionRequired: true,
        module: DRA_MODULES.admin,
        pathname: "/user-dashboard/admin",
    },
    {
        value: 5,
        label: "Assign work",
        permissionRequired: true,
        module: DRA_MODULES.assignWork,
        pathname: "/user-dashboard/assign-tasks",
    },
    {
        value: 6,
        label: "Reports",
        permissionRequired: true,
        module: DRA_MODULES.reports,
        pathname: "/user-dashboard/reports",
    },
    {
        value: 7,
        label: "User Goups",
        permissionRequired: true,
        module: DRA_MODULES.userGroups,
        pathname: "/user-dashboard/user-groups",
    },
];

const Header = ({ headerRef }) => {
    const [activeTab, setActiveTab] = useState(1);
    const navigate = useNavigate();
    const { pathname, search, state } = useLocation();
    const loggedInUserDetails = useContext(UserContext);

    useEffect(() => {
        let currentPathname = state?.pathname || pathname;
        let tabItem = tabItems.find((ele) => ele.pathname == currentPathname);
        if (tabItem) {
            setActiveTab(tabItem.value);
        }
    }, [pathname]);
    const handleTabChange = (event, newTab) => {
        setActiveTab(newTab);
        const tabItem = tabItems.find((item) => item.value == newTab);
        navigate(tabItem.pathname);
    };
    return (
        <Box ref={headerRef} sx={{ padding: "0 3rem" }}>
            <Box sx={{ display: "flex" }}>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    {tabItems.map((item) => {
                        if (item.permissionRequired && !checkPermission(loggedInUserDetails, item.module).read) {
                            return;
                        }
                        return <Tab sx={{ textTransform: "none" }} key={item.value} value={item.value} label={item.label}></Tab>;
                    })}
                </Tabs>
            </Box>
            <Divider style={{ marginTop: "0" }}></Divider>
        </Box>
    );
};

export default Header;
