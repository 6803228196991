import React from "react";
// import "./index.css";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";

import { Delete } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardHeader, Divider, IconButton, Typography } from "@mui/material";
import TextArea from "antd/lib/input/TextArea";
import AccoladesForm from "./AccoladesForm";
import DurationCard from "./DurationCard";
import ProjectForm from "./ProjectForm";
import SkillsForm from "./SkillsForm";
const EducationForm = ({ form }) => {
    return (
        <Card variant="outlined" sx={{ p: 2, backgroundColor: "profileCreatorForm.main" }}>
            <CardHeader sx={{ p: 0 }} title={<Typography variant="body1">Educations</Typography>} />
            <Divider sx={{ mb: 3 }} />
            <Form.List name="education">
                {(educationFields, { add: addEducation, remove: removeEducation }) => (
                    <>
                        {educationFields.map(({ key, name }) => {
                            return (
                                <Accordion
                                    key={key}
                                    sx={{
                                        backgroundColor: "profileCreatorForm.education",
                                        marginBottom: 2,
                                        borderRadius: 1,
                                        boxShadow: 3,
                                        "&:hover": {
                                            boxShadow: "0 4px 10px rgba(25, 118, 210, 0.3)",
                                        },
                                    }}
                                >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
                                            <Typography>{`Education ${key + 1}`}</Typography>
                                            <Box display="flex" alignItems="center">
                                                <IconButton onClick={() => removeEducation(name)} size="small">
                                                    <Delete />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {/* education details*/}
                                        <Card variant="outlined" sx={{ p: 2, backgroundColor: "profileCreatorForm.educationDetails" }}>
                                            <CardHeader sx={{ p: 0 }} title={<Typography variant="body1">Education details</Typography>} />
                                            <Divider sx={{ mb: 3 }} />
                                            <Card variant="outlined" sx={{ p: 2, mt: 2, backgroundColor: "profileCreatorForm.main" }}>
                                                <CardHeader sx={{ p: 0 }} title={<Typography variant="body1">Degree</Typography>} />
                                                <Divider sx={{ mb: 3 }} />

                                                <Form.List name={[name, "education_details", "degree"]}>
                                                    {(fields, { add, remove }) => (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "start",
                                                                alignItems: "center",
                                                                flexWrap: "wrap",
                                                            }}
                                                        >
                                                            {fields.map(({ key, name, fieldKey }) => (
                                                                <Space key={key} align="baseline">
                                                                    <Form.Item
                                                                        name={[name, "degree"]}
                                                                        fieldKey={[fieldKey, "degree"]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: `Please input the degree`,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            placeholder={"degree"}
                                                                            addonAfter={
                                                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Space>
                                                            ))}
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                Add Degree
                                                            </Button>
                                                        </div>
                                                    )}
                                                </Form.List>
                                            </Card>
                                            <Card
                                                variant="outlined"
                                                sx={{ p: 2, mt: 2, mb: 1, backgroundColor: "profileCreatorForm.major" }}
                                            >
                                                <CardHeader sx={{ p: 0 }} title={<Typography variant="body1">Major</Typography>} />
                                                <Divider sx={{ mb: 3 }} />
                                                <Form.List name={[name, "education_details", "major"]}>
                                                    {(fields, { add, remove }) => (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "start",
                                                                alignItems: "center",
                                                                flexWrap: "wrap",
                                                            }}
                                                        >
                                                            {fields.map(({ key, name, fieldKey }) => (
                                                                <Space key={key} align="baseline">
                                                                    <Form.Item
                                                                        name={[name, "major"]}
                                                                        fieldKey={[fieldKey, "major"]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: `Please input the ${"major"}`,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            placeholder={"major"}
                                                                            addonAfter={
                                                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Space>
                                                            ))}
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                Add Major
                                                            </Button>
                                                        </div>
                                                    )}
                                                </Form.List>
                                            </Card>

                                            <Form.Item label={"program_type"} name={[name, "education_details", "program_type"]}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item label={"specialization"} name={[name, "education_details", "specialization"]}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item label="description" name={[name, "education_details", "description"]}>
                                                <TextArea />
                                            </Form.Item>
                                            <Form.Item label={"grades"} name={[name, "education_details", "grades"]}>
                                                <Input />
                                            </Form.Item>
                                            <ProjectForm fieldPath={["education", name]} name={[name, "projects"]} form={form} />
                                            <SkillsForm
                                                fieldPath={["education", name, "education_details"]}
                                                name={[name, "education_details", "skills"]}
                                                form={form}
                                            />
                                            <AccoladesForm
                                                fieldPath={["education", name, "education_details"]}
                                                name={[name, "education_details", "accolades"]}
                                                form={form}
                                            ></AccoladesForm>
                                        </Card>

                                        {/* Duration */}
                                        <DurationCard fieldPath={["education", name]} name={[name]} form={form} />

                                        {/* school info*/}
                                        <Card variant="outlined" sx={{ p: 2, mt: 2, backgroundColor: "profileCreatorForm.main" }}>
                                            <CardHeader sx={{ p: 0 }} title={<Typography variant="body1">School Info</Typography>} />
                                            <Divider sx={{ mb: 3 }} />
                                            {["name", "location", "domain", "department"].map((field, idx) => {
                                                return (
                                                    <Form.Item key={idx} label={field} name={[name, "school_info", field]}>
                                                        <Input />
                                                    </Form.Item>
                                                );
                                            })}
                                        </Card>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                        <Button type="dashed" onClick={() => addEducation()} block icon={<PlusOutlined />}>
                            Add Education
                        </Button>
                    </>
                )}
            </Form.List>
        </Card>
    );
};

export default EducationForm;
