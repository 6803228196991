import React, { createContext, useState, useMemo } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { themes } from "../theme";
import { GlobalStyles } from "@mui/material";

export const ThemeContext = createContext();

export const ThemeContextProvider = ({ children }) => {
    const [themeName, setThemeName] = useState("light");

    const theme = useMemo(() => themes[themeName], [themeName]);

    const toggleTheme = (newTheme) => {
        setThemeName(newTheme);
    };
    const GlobalStyle = () => (
        <GlobalStyles
            styles={(theme) => ({
                body: {
                    fontFamily: theme.typography.fontFamily,
                    margin: 0,
                    padding: 0,
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary,
                },
            })}
        />
    );

    return (
        <ThemeContext.Provider value={{ themeName, toggleTheme }}>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};
