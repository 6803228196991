import { createTheme } from "@mui/material";

const baseTheme = createTheme({
    typography: {
        fontFamily: "sans-serif",
        fontSize: 14,
        h1: { fontSize: "2rem", fontWeight: 500 },
        h2: { fontSize: "1.75rem", fontWeight: 500 },
        h3: { fontSize: "1.5rem", fontWeight: 500 },
        body1: { fontSize: "1rem" },
        body2: { fontSize: "0.875rem" },
    },
    shape: {
        borderRadius: 6,
    },
    spacing: 8,
    custom: {
        heights: {
            header: 56,
            navbar: 97,
            filterbar: 74,
            subTabs: 48,
        },
        top: {
            level0: 0,
            level1: 56,
            level2: 97 + 56,
            level3: 97 + 56 + 74,
        },
        iconSizes: {
            small: 16,
            medium: 24,
            large: 32,
            extraLarge: 48,
        },
    },
    zIndex: {
        header: 1000,
        navbar: 990,
        subTabs: 980,
    },
    transitions: {
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            standard: 300,
            complex: 375,
            enteringScreen: 225,
            leavingScreen: 195,
        },
        easing: {
            easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
            easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
            easeIn: "cubic-bezier(0.4, 0, 1, 1)",
            sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    textTransform: "none",
                    transition: theme.transitions.create(["background-color", "color", "border"], {
                        duration: theme.transitions.duration.short,
                        easing: theme.transitions.easing.easeInOut,
                    }),
                    backgroundColor: ownerState.selected ? theme.palette.action.active : theme.palette.background.default,
                    color: ownerState.selected ? theme.palette.getContrastText(theme.palette.action.active) : theme.palette.text.primary,
                    border: `1px solid ${ownerState.selected ? theme.palette.primary.main : theme.palette.divider}`,
                    "&:hover": {
                        backgroundColor: ownerState.selected ? theme.palette.primary.light : theme.palette.action.hover,
                        color: ownerState.selected ? theme.palette.getContrastText(theme.palette.action.focus) : theme.palette.text.primary,
                    },
                }),
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 32,
                    height: 32,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    height: "auto !important",
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: ({ theme }) => ({
                    backgroundColor: theme.palette.background.light,
                }),
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: ({ theme }) => ({
                    backgroundColor: theme.palette.background.light,
                }),
              
                
            },
          },
    },
});

export default baseTheme;
