import { Button, Card, CardActions, CardContent, IconButton, Paper, TextField } from "@mui/material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
const InputCard = ({ onSubmit, label, inputLabel, groupId }) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState("");

    const toggleAddCard = () => {
        setOpen(!open);
    };
    const handleValueChange = (event) => {
        setValue(event.target.value);
    };
    const handleAddInput = () => {
        onSubmit(value, groupId);
        setValue("");
        setOpen(false);
    };

    return open ? (
        <Card sx={{ background: "#F4F5F7" }}>
            <CardContent>
                <TextField onChange={handleValueChange} fullWidth label={inputLabel} variant="standard" />
            </CardContent>
            <CardActions>
                <Button sx={{ textTransform: "none" }} disabled={value.length == 0} onClick={handleAddInput}>
                    Add
                </Button>
                <IconButton onClick={toggleAddCard}>
                    <CloseIcon />
                </IconButton>
            </CardActions>
        </Card>
    ) : (
        <Button
            startIcon={<AddIcon />}
            sx={{
                background: "#F4F5F7",
                borderRadius: "12px",
                color: "#172B4D",
                textTransform: "none",
                justifyContent: "flex-start",
                paddingRight: "3rem",
            }}
            onClick={toggleAddCard}
        >
            {label}
        </Button>
    );
};
export default InputCard;
