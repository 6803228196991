import React, { useEffect } from "react";
// import "./index.css";
import { Checkbox, DatePicker, Form } from "antd";
import { get, set } from "lodash";

import { Card, CardHeader, Divider, Typography } from "@mui/material";
import dayjs from "dayjs";

const DurationCard = ({ fieldPath, name, form }) => {
    useEffect(() => {
        const fullPathToEndDate = [...fieldPath, "duration", "end_date"];
        const fullPathToStartDate = [...fieldPath, "duration", "start_date"];
        const initialEndDate = get(form.getFieldsValue(true), fullPathToEndDate);
        const initialStartDate = get(form.getFieldsValue(true), fullPathToStartDate);

        const updatedValue = form.getFieldsValue(true);
        if (initialEndDate === undefined || initialEndDate?.length < 1) {
            set(updatedValue, fullPathToEndDate, null);
        }
        if (initialStartDate === undefined || initialStartDate?.length < 1) {
            set(updatedValue, fullPathToStartDate, null);
        }
        form.setFieldsValue(updatedValue);
    }, []);

    const handleEndDateChange = (newDate) => {
        const updatedValue = form.getFieldsValue(true);

        set(updatedValue, [...fieldPath, "duration", "to_present"], !newDate);
        form.setFieldsValue(updatedValue);
    };

    return (
        <Card variant="outlined" sx={{ p: 2, mt: 2, backgroundColor: "profileCreatorForm.duration" }}>
            <CardHeader sx={{ p: 0 }} title={<Typography variant="body1">Duration</Typography>} />
            <Divider sx={{ mb: 3 }} />
            <Form.Item
                name={[...name, "duration", "start_date"]}
                label="Start Date"
                normalize={(value) => (value && !dayjs.isDayjs(value) ? dayjs(value) : value)}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                name={[...name, "duration", "end_date"]}
                label={"End Date"}
                normalize={(value) => (value && !dayjs.isDayjs(value) ? dayjs(value) : value)}
            >
                <DatePicker onChange={handleEndDateChange} allowClear />
            </Form.Item>
            <Form.Item label="To Present" name={[...name, "duration", "to_present"]} valuePropName="checked">
                <Checkbox />
            </Form.Item>
        </Card>
    );
};

export default DurationCard;
