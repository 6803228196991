import { Alert, Modal, Select, Typography, Checkbox, Spin } from "antd";
import React, { useMemo, useRef, useState } from "react";
import ReactSelect from "react-select";
import { SectionTitle, SectionWrapper } from "./OptoutModal.styled";
const CheckboxGroup = Checkbox.Group;

export const COMPLIANCE_OPTIONS = [
    {
    value: "compliance",
    label: "Compliance",
    },
    {
    value: "compliance_retired",
    label: "Compliance Retired",
    },
];

export const AGENCY_SOURCE_OPTIONS = [
    {
      'label': 'Incogni',
      'value': 'incogni'
    },
    {
      'label': 'Atlas',
      'value': 'atlas'
    },
    {
        'label': 'CR Consumer',
        'value': 'cr_consumer',
    },
    {
        'label': 'EMRX',
        'value': 'emrx',
    
    },
    {
        'label': 'Optery',
        'value': 'optery',
    
    },
    {
        'label': 'Pure Privacy',
        'value': 'pure_consumer',
    }

  ];
  
export const INDIVIDUAL_SOURCE_OPTIONS = [
    {
        'label': 'Direct email to Privacy group',
        'value': 'direct email to privacy group',
    },
    {
        'label': '#do-not-sell-form-notifications slack channel',
        'value': '#do-not-sell-form-notifications slack channel'
    }
];

export const AGENCY_SOURCE = AGENCY_SOURCE_OPTIONS.map((sourceObj) => sourceObj.value);

export const OPT_OUT_CATEGORISED_SOURCE_NAMES = [
    {
        label: 'Individual',
        options: INDIVIDUAL_SOURCE_OPTIONS,
    },
    {
        label: 'Agency',
        options: AGENCY_SOURCE_OPTIONS,
    },
];

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

const formatGroupLabel = (data) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
    </div>
);

const OptoutModals = ({
    isSelectionModalOpen,
    linkedinsOptions,
    emailsOptions,
    phonesOptions,
    isEmailPresent,
    lookedUpLinkedin,
    lookedUpEmails,
    isOptOutRequestLoading,
    handleSelectionModalClose,
    handleOptOut,
    resetSearchForm
    }) => {

    const [complianceType, setComplianceType] = useState(null);
    const [optOutSourceSelectedOption, setOptOutSourceSelectedOption] = useState(null);
    // confirmation modal state
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    
    const [checkedLinkedins, setCheckedLinkedins] = useState([]);
    const [checkedEmails, setCheckedEmails] = useState([]);
    const [checkedPhones, setCheckedPhones] = useState([]);

    const handleLinkedinsChange = (checkedValues) => {
        setCheckedLinkedins(checkedValues);
    }

    const handleEmailsChange = (checkedValues) => {
        setCheckedEmails(checkedValues);
    }

    const handlePhonesChange = (checkedValues) => {
        setCheckedPhones(checkedValues);
    }

    const disableSelectionModalSubmit = useMemo(() => {
        const hasEmails = !!emailsOptions?.length ? !!checkedEmails?.length : !!lookedUpEmails?.length;
        const hasLinkedins = !!linkedinsOptions?.length ? !!checkedLinkedins?.length : !!lookedUpLinkedin?.length;
        const hasPhoneNumbers = !!checkedPhones?.length
        const hasInput = (hasEmails || hasLinkedins || hasPhoneNumbers);
        
        const disableSelectionModalSubmit = complianceType === null || 
        complianceType === undefined ||
        !complianceType?.length ||
        optOutSourceSelectedOption === null ||
        optOutSourceSelectedOption === undefined ||
        !optOutSourceSelectedOption?.value?.length || 
        !hasInput;
        

        return disableSelectionModalSubmit;
    }, [complianceType, optOutSourceSelectedOption, checkedEmails, emailsOptions, lookedUpEmails, linkedinsOptions, checkedLinkedins, lookedUpLinkedin, checkedPhones]);

    // show the confirmation modal
    const handleOkSelectionModal = () => {
        handleOpenConfirmationModal();
    }

    // close the selection modal
    const handleCancelSelectionModal = () => {
        resetSearchForm();
        handleSelectionModalClose();
    }

    const handleComplianceOnChange = (newValue) => {
        setComplianceType(newValue);
    }

    const handleOpenConfirmationModal = () => {
        setIsConfirmationModalOpen(true);
    }

    const handleCancelConfirmationModal = () => {
        setIsConfirmationModalOpen(false);
    }

    const handleOkConfirmationModal = () => {
        handleOptOut(
            complianceType,
            optOutSourceSelectedOption,
            checkedLinkedins,
            checkedEmails,
            checkedPhones,
            handleCancelConfirmationModal
        );
    }

    const handleSourceNameOnChange= (newValue) => {
        setOptOutSourceSelectedOption(newValue)
    };

    return (
        <>
            <Modal
                style={{ marginTop: "9rem" }}
                title={<div>Selected profile/email will be removed.</div>}
                visible={isSelectionModalOpen}
                onOk={handleOkSelectionModal}
                onCancel={handleCancelSelectionModal}
                okButtonProps={{
                    disabled: disableSelectionModalSubmit
                }}
            >
                <>
                    {isEmailPresent && (
                        <Alert
                            style={{
                                textAlign: "center",
                                marginBottom: "20px"
                            }}
                            message="Email exist! Need to be handled through the opt-out flow."
                            type="warning"
                        />
                    )}
                    <SectionWrapper>
                        <div style={{marginBottom: "4px"}}>
                            <SectionTitle>
                                Compliance Type{" "}
                                <span style={{ color: "red" }}>(required)</span>
                            </SectionTitle>
                            <Select
                                value={complianceType}
                                placeholder="Please select type"
                                style={{
                                    width: 170,
                                }}
                                onChange={handleComplianceOnChange}
                                options={COMPLIANCE_OPTIONS}
                            />
                        </div>
                        <div style={{width: "100%"}}>
                            <SectionTitle>
                                Source Name <span style={{ color: "red" }}>(required)</span>
                            </SectionTitle>
                            <ReactSelect
                                isClearable
                                options={OPT_OUT_CATEGORISED_SOURCE_NAMES}
                                value={optOutSourceSelectedOption}
                                onChange={handleSourceNameOnChange}
                                formatGroupLabel={formatGroupLabel}
                                width={"100%"}
                                placeholder={"Select a source name"}
                            />
                        </div>
                    </SectionWrapper>
                    <SectionTitle>
                        Profiles
                    </SectionTitle>
                    {!!linkedinsOptions?.length ? (
                        <>
                            <CheckboxGroup
                                options={linkedinsOptions}
                                value={checkedLinkedins}
                                onChange={handleLinkedinsChange}
                            />
                            <br />
                        </>
                    ) : (
                    <>
                        <Typography>No profiles found</Typography>
                        {!!lookedUpLinkedin?.length && (
                            <Typography>
                                Note:- You can proceed to mark{" "}
                                <span>
                                    <a href={lookedUpLinkedin} target="_blank">
                                    {lookedUpLinkedin}
                                    </a>
                                </span>{" "}
                                to not to be contacted in future.
                            </Typography>
                        )}
                    </>
                    )}

                    <br />
                    <SectionTitle>E-mails</SectionTitle>
                    {!!emailsOptions?.length ? (
                        <>
                            <CheckboxGroup
                                options={emailsOptions}
                                value={checkedEmails}
                                onChange={handleEmailsChange}
                            />
                            <br />
                        </>
                    ) : (
                    <>
                        <Typography>No emails found</Typography>
                        {!!lookedUpEmails?.length && (
                        <>
                            <Typography>
                                Note:- You can proceed to mark
                                {lookedUpEmails?.map((email, index) => (
                                    <React.Fragment key={email}>
                                    {" "}
                                    <span>
                                        <a href="#">{email}</a>
                                    </span>
                                    </React.Fragment>
                                ))}{" "}
                                to not to be contacted in the future.
                            </Typography>
                        </>
                        )}
                    </>
                    )}
                    <br />
                    <SectionTitle>Phones</SectionTitle>
                    {!!phonesOptions?.length ? (
                        <>
                            <CheckboxGroup 
                                options={phonesOptions}
                                onChange={handlePhonesChange}
                                value={checkedPhones}
                            />
                            <br />
                        </>
                        ) :
                        <Typography>No Phone numbers were provided in the search</Typography>
                    }
                </>
            </Modal>
            <Modal
                style={{ marginTop: "14rem" }}
                title="Confirm?"
                visible={isConfirmationModalOpen}
                onOk={handleOkConfirmationModal}
                onCancel={handleCancelConfirmationModal}
                okText="Yes"
                cancelText="No"
            >
                {isOptOutRequestLoading ? (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                        <Spin />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <span>Removing! Please wait...</span>
                        </div>
                    </>
                ) : (
                    <p>Do you agree to remove selected information?</p>
                )}
            </Modal>
        </>
    );
}

export default OptoutModals;
