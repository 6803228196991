import { Input, Button, Select, Form, InputNumber, message } from "antd";
import RequestForm from "./RequestForm";
import { method, values } from "lodash";
import { url } from "../../url";
import { common } from "@mui/material/colors";
import { useContext, useState } from "react";
import UserContext from "../../UserContext";
import dayjs from "dayjs";
import axios from "axios";

const initialCompanyData = {
  lnkd_company_id: "",
  lnkd_company_id_numeric: null,
  company_domain: "",
  company_name: "",
  meta_ref: "",
  geo_codes: [],
  geo_codes_exclude: [],
  title_keywords: [],
  title_keywords_exclude: [],
  current_company_ids: [],
  past_company_ids: [],
  functions: [],
  keywords: "",
  limit: 1,
  year_in_current_company: [],
  year_in_current_position: [],
  refresh_label: "",
};
const RefreshCompanyProfilesRequest = () => {
  const loggedInUserDetails = useContext(UserContext);
  const [isCompanyDetailsLoading, setIsCompanyDetailsLoading] = useState(false);
  const [formValues] = Form.useForm();

  const handleRequestFormSubmit = async () => {
    try {
      const values = await formValues.validateFields();
      values.past_company_ids = values.past_company_ids.map((ele) => parseInt(ele));
      values.current_company_ids = values.current_company_ids.map((ele) => parseInt(ele));
      const response = await axios.post(
        url.refreshCompanyProfiles,
        { requestData: values, loggedInUserDetails: loggedInUserDetails },
        {
          params: {
            domain: values.company_domain,
          },
        }
      );
      if (response.data?.data?.request_id == null) {
        message.error(response.data.data.message);
      } else {
        message.success(`Refresh request sent successfully with request id: ${response?.data?.data?.request_id}.`);
      }
    } catch (e) {
      if (e.message) {
        message.error(e.message);
      }
      console.log("Failed to refresh company profiles.", e);
    }
  };
  const handleCheckExternalEmployeesCount = async () => {
    try {
      const values = await formValues.validateFields();
      values.past_company_ids = values.past_company_ids.map((ele) => parseInt(ele));
      values.current_company_ids = values.current_company_ids.map((ele) => parseInt(ele));
      values.refresh_label = "check_count_" + values.refresh_label;
      values.limit = 1;
      values.refresh_label = `check_count_${values.company_domain}_${dayjs().format("DD_MM_YYYY")}`;
      const response = await axios.post(
        url.refreshCompanyProfiles,
        { requestData: values, loggedInUserDetails: loggedInUserDetails, checkExternalEmployeesCount: true },
        {
          params: {
            domain: values.company_domain,
          },
        }
      );
      if (response.data?.data?.request_id == null) {
        message.error(response.data.data.message);
      } else {
        message.success(`Refresh request sent successfully with request id: ${response?.data?.data?.request_id}.`);
      }
    } catch (e) {
      if (e.message) {
        message.error(e.message);
      }
      console.log("Failed to refresh company profiles.", e);
    }
  };

  const getCompanyDetails = async (event) => {
    try {
      setIsCompanyDetailsLoading(true);
      const domain_name = event.target.value;
      const response = await axios({
        method: "get",
        url: url.getCompanyDetails,
        params: {
          company_domain: domain_name,
        },
      });
      const companyDetails = response?.data?.data?.company_details;
      let companyDetailsAutoFillData = {
        status: companyDetails?.status,
        company_name: companyDetails?.company_name || "",
        meta_ref: companyDetails?.meta_ref || "",
        lnkd_company_id: companyDetails?.lnkd_company_id || "",
        lnkd_company_id_numeric: companyDetails?.lnkd_company_id_numeric || "",
        current_company_ids: isNaN(parseInt(companyDetails.lnkd_company_id_numeric))
          ? []
          : [parseInt(companyDetails?.lnkd_company_id_numeric)],
        refresh_label: companyDetails?.refresh_label || `full_refresh_${domain_name}_${dayjs().format("DD_MM_YYYY")}`,
      };
      if (companyDetails.status == "not_found") {
        message.info("Company details not found!");
      }
      formValues.setFieldsValue(companyDetailsAutoFillData);
    } catch (e) {
      formValues.resetFields(["company_name", "meta_ref", "lnkd_company_id", "lnkd_company_id_numeric", "current_company_ids", "refresh_label"]);
      message.error("Failed to get company details.");
      console.log("Failed to get company details: ", e);
    } finally {
      setIsCompanyDetailsLoading(false);
    }
  };

  return (
    <RequestForm
      isCompanyDetailsLoading={isCompanyDetailsLoading}
      setIsCompanyDetailsLoading={setIsCompanyDetailsLoading}
      initialCompanyData={initialCompanyData}
      handleRequestFormSubmit={handleRequestFormSubmit}
      handleCheckExternalEmployeesCount={handleCheckExternalEmployeesCount}
      formValues={formValues}
      getCompanyDetails={getCompanyDetails}
      isDisabled={false}
    ></RequestForm>
  );
};

export default RefreshCompanyProfilesRequest;
