import ProtectedRoutes from "../components/Common/ProtectedRoutes";
import Homepage from "../components/Home/home";
import InstantProfileRefresh from "../components/InstantProfileRefresh/InstantProfileRefresh";
import InstantProfileRefreshHistory from "../components/InstantProfileRefresh/InstantProfileRefreshHistory/RefreshHistory";
import InstantProfileRefreshRequest from "../components/InstantProfileRefresh/InstantProfileRefreshRequest/InstantProfileRefreshRequest";
import LeadershipProfile from "../components/LeadershipProfile/LeadershipProfile";
import ClientSpecificOptOut from "../components/OptOut/clientSpecificOptOutjs/ClientSpecificOptOut";
import OptOut from "../components/OptOut/OptOut";
import OptOutByDomain from "../components/OptOut/OptOutByDomain/OptOutByDomain";
import ProfileOptOut from "../components/OptOut/ProfileOptOut/ProfileOptOut";
import PendingShorlist from "../components/PendingShortlist/PendingShortlist";
import TimeLine from "../components/TimeLine/TimeLine";
import { DRA_MODULES } from "../components/utils/rbacUtils";

const { Route, Navigate, Routes } = require("react-router-dom");
const { default: MainLayout } = require("../layouts/main-layout/MainLayout");

const appRoutes = [
    {
        path: "/",
        element: MainLayout,
        children: [
            { path: "add-missing-emails", index: true },
            { path: "add-missing-emails", element: Homepage, permissionRequired: true, module: DRA_MODULES.addMissingEmails },
            { path: "pending-shortlist", element: PendingShorlist, permissionRequired: true, module: DRA_MODULES.pendingShortList },
            {
                path: "opt-out",
                element: OptOut,
                permissionRequired: true,
                module: DRA_MODULES.optOut,
                children: [
                    { path: "profile-opt-out", index: true },
                    { path: "profile-opt-out", element: ProfileOptOut },
                    { path: "by-domain", element: OptOutByDomain },
                    { path: "client-specific-opt-out", element: ClientSpecificOptOut },
                ],
            },
            {
                path: "timeline",
                element: TimeLine,
                permissionRequired: true,
                module: DRA_MODULES.timeLine,
            },
            {
                path: "/instant-profile-refresh",
                element: InstantProfileRefresh,
                permissionRequired: true,
                module: DRA_MODULES.instantProfileRefresh,
                children: [
                    { path: "refresh-request", index: true },
                    { path: "refresh-request", element: InstantProfileRefreshRequest },
                    { path: "refresh-history", element: InstantProfileRefreshHistory },
                ],
            },
            {
                path: "/leadership-profile",
                element: LeadershipProfile,
                permissionRequired: true,
                module: DRA_MODULES.leadershipProfile,
            },
        ],
    },
];

const RenderRoutes = ({ loggedInSessionUser, loggedInUser, logoutUser }) => {
    const helper = (routes) =>
        routes.map((route, index) => {
            if (route.index) {
                return <Route key={index} index element={<Navigate to={route.path} replace />} />;
            }
            return (
                <Route
                    key={index}
                    path={route?.path}
                    element={
                        <ProtectedRoutes
                            Component={route.element}
                            loggedInUser={loggedInUser}
                            loggedInSessionUser={loggedInSessionUser}
                            loggedInUserDetails={loggedInSessionUser._id ? loggedInSessionUser : loggedInUser}
                            logoutUser={logoutUser}
                            permissionRequired={route?.permissionRequired}
                            module={route?.module}
                        />
                    }
                >
                    {route.children && helper(route.children)}
                </Route>
            );
        });

    return <Routes>{helper(appRoutes)}</Routes>;
};

export default RenderRoutes;
