import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const tabItems = [
    {
        value: 1,
        label: "Refresh Request",
        pathname: "/instant-profile-refresh/refresh-request",
    },
    {
        value: 2,
        label: "Refresh History",
        pathname: "/instant-profile-refresh/refresh-history",
    },
];

const InstantProfileRefresh = ({ loggedInUserDetails, logoutUser }) => {
    const { pathname, search, state } = useLocation();
    const [activeTab, setActiveTab] = useState(1);
    const tabRef = useRef(null);
    const navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        const tabItem = tabItems.find((item) => item.value == newValue);
        navigate(tabItem.pathname);
    };

    useEffect(() => {
        let currentPathname = state?.pathname || pathname;
        let tabItem = tabItems.find((ele) => ele.pathname == currentPathname);
        if (tabItem) {
            setActiveTab(tabItem.value);
            navigate(tabItem.pathname);
        }
    }, []);

    //Styling
    const theme = useTheme();
    const heights = theme.custom.heights;
    return (
        <div>
            <Box>
                <Tabs
                    sx={{
                        zIndex: theme.zIndex.subTabs,
                        height: heights.subTabs,
                        borderBlockEnd: theme.palette.border.primary,
                        backgroundColor: "background.paper",
                        position: "sticky",
                        top: heights.header + heights.navbar,
                    }}
                    ref={tabRef}
                    centered
                    value={activeTab}
                    onChange={handleTabChange}
                >
                    {tabItems.map((item) => {
                        return <Tab value={item.value} label={item.label}></Tab>;
                    })}
                </Tabs>
                <Box>
                    <Outlet context={{ tabRef }}></Outlet>
                </Box>
            </Box>
        </div>
    );
};

export default InstantProfileRefresh;
