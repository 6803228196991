import React, { useContext, useMemo } from "react";
import DraLogo from "../../assets/DRA.svg";
import { useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Layout, Image, Row, Col, Dropdown, Space } from "antd";
import { checkPermission, DRA_MODULES } from "../../components/utils/rbacUtils";
import TabContext from "../../TabContext";
import { TAB_ROUTE_TO_NAME_ID_MAP } from "../navbar/Navbar"; 
import { Avatar, Box, Card, CardHeader, Menu, MenuItem, Stack, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";

const MainHeader = ({ loggedInUserDetails, logoutUser }) => {
    const activeTab = useContext(TabContext);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuItems = [
        {
            key: "reports",
            label: "Reports",
            permissionRequired: true,
            module: DRA_MODULES.reports,
            onClick: () => {
                navigate("/reports");
            },
        },
        {
            key: "userGroups",
            label: "User Groups",
            permissionRequired: true,
            module: DRA_MODULES.userGroups,
            onClick: () => {
                navigate("/user-groups");
            },
        },
        {
            key: "userDashboard",
            label: "User Dashboard",
        },
        {
            key: "Logout",
            label: "Logout",
            onClick: () => logoutUser(),
        },
    ];

    const navigateToApp = () => {
        const path = Object.keys(TAB_ROUTE_TO_NAME_ID_MAP).find((key) => TAB_ROUTE_TO_NAME_ID_MAP[key]?.id === activeTab[0]) || "/";
        navigate(path);
    };

    return (
        <Box
            sx={(theme) => ({
                position: "sticky",
                top: 0,
                zIndex: theme.zIndex.header,
                display: "flex",
                justifyContent: "space-between",
                pl: 2,
                pr: 2,
                alignItems: "center",
                height: theme.custom.heights.header,
                backgroundColor: "background.paper",
                borderBlockEnd: theme.palette.border.primary,
                overflow: "hidden",
            })}
        >
            <Card elevation={0} onClick={navigateToApp} sx={{ cursor: "pointer" }}>
                <CardHeader
                    avatar={<img src={DraLogo} alt="DRA Logo" width="32px" height="32px" />}
                    title="DATA RESEARCH APPLICATION"
                    subheader="Findem Inc."
                />
            </Card>
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <Tooltip title="Your Profile and settings">
                    <IconButton onClick={handleClick}>
                        <Avatar alt={loggedInUserDetails.name} src="dsg.com" />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,

                            "&::before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 24,
                                width: 10,
                                height: 10,
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Stack direction={"row"} padding={"1rem 1.5rem"} gap={"1rem"}>
                    <Avatar sx={{ width: 60, height: 60 }}>{loggedInUserDetails.name[0].toUpperCase()}</Avatar>
                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography variant="h3" color={"text.primary"} fontWeight="600">
                            {loggedInUserDetails.name}
                        </Typography>
                        <Typography color={"text.secondary"} sx={{ opacity: "0.9", fontSize: "1em" }}>
                            {loggedInUserDetails.email}
                        </Typography>
                    </Box>
                </Stack>
                <Divider />

                <MenuItem onClick={() => navigate("/user-dashboard")}>User Dashboard</MenuItem>
                <Divider />
                <MenuItem onClick={logoutUser}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default MainHeader;
