import React, { useEffect, useState } from "react";
// import "./index.css";
import { CloseOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { PlusOutlined, MinusCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { get, set } from "lodash";
import { Button, Form, DatePicker, Checkbox, Space, Select, Input } from "antd";

import ArrOfStrUIHandler from "../ArrOfStrUIHandler";
import dayjs from "dayjs";
import { Card, CardHeader, Divider, IconButton, Typography } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const AccoladesForm = ({ fieldPath, name, form }) => {
    useEffect(() => {
        const fullPathToEndorsements = [...fieldPath, "accolades", "endorsements"];
        const fullPathToAwards = [...fieldPath, "accolades", "awards"];
        const awards = get(form.getFieldsValue(true), fullPathToAwards);
        const endorsements = get(form.getFieldsValue(true), fullPathToEndorsements);
        const updatedFormValues = form.getFieldsValue(true);
        if (!awards) {
            set(updatedFormValues, fullPathToAwards, []);
        }
        if (!endorsements) {
            set(updatedFormValues, fullPathToEndorsements, []);
        }
        form.setFieldsValue(updatedFormValues);
    }, []);
    return (
        <Card variant="outlined" sx={{ p: 2, mt: 2, backgroundColor: "profileCreatorForm.main" }}>
            <CardHeader sx={{ p: 0 }} title={<Typography variant="body1">Accolades</Typography>} />
            <Divider sx={{ mb: 3 }} />
            <Form.List name={[...name, "awards"]}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name }) => (
                            <Card variant="outlined" sx={{ p: 2, mt: 2, backgroundColor: "profileCreatorForm.awards" }}>
                                <CardHeader
                                    action={
                                        <IconButton onClick={() => remove(name)} aria-label="settings">
                                            <RemoveCircleIcon />
                                        </IconButton>
                                    }
                                    sx={{ p: 0 }}
                                    title={<Typography variant="body1">{`Awards ${key + 1}`}</Typography>}
                                />
                                <Divider sx={{ mb: 3 }} />

                                <Form.Item label={"name"} name={[name, "name"]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={[name, "date"]}
                                    label="Date"
                                    normalize={(value) => (value && !dayjs.isDayjs(value) ? dayjs(value) : value)}
                                >
                                    <DatePicker />
                                </Form.Item>
                                <Form.Item label={"description"} name={[name, "description"]}>
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item label={"issuer"} name={[name, "issuer"]}>
                                    <Input />
                                </Form.Item>
                            </Card>
                        ))}
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Awards
                        </Button>
                    </>
                )}
            </Form.List>
            <Form.List name={[...name, "endorsements"]}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name }) => (
                            <Card variant="outlined" sx={{ p: 2, mt: 2, backgroundColor: "profileCreatorForm.endorsements" }}>
                                <CardHeader
                                    action={
                                        <IconButton onClick={() => remove(name)} aria-label="settings">
                                            <RemoveCircleIcon />
                                        </IconButton>
                                    }
                                    sx={{ p: 0 }}
                                    title={<Typography variant="body1">{`Endorsement ${key + 1}`}</Typography>}
                                />

                                <Form.Item label={"endorser"} name={[name, "endorser"]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label={"category"} name={[name, "category"]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label={"capacity"} name={[name, "capacity"]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={[name, "date"]}
                                    label="Date"
                                    normalize={(value) => (value && !dayjs.isDayjs(value) ? dayjs(value) : value)}
                                >
                                    <DatePicker />
                                </Form.Item>
                                <Form.Item label={"description"} name={[name, "description"]}>
                                    <Input.TextArea />
                                </Form.Item>
                            </Card>
                        ))}
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Endorsements
                        </Button>
                    </>
                )}
            </Form.List>
        </Card>
    );
};

export default AccoladesForm;
