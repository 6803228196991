import { useState, useEffect, forwardRef } from "react";
import Header from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";
import { url } from "../../url";
import axios from "axios";
import { Spin, Switch, Tooltip } from "antd";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  Button,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  MenuItem,
  InputLabel,
  Link,
  Pagination,
  Stack,
  Snackbar,
  Select,
} from "@mui/material";

import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const notification = {
  show: false,
  variant: null,
  message: null,
  errorMessage: null,
};

const FakeCxoHeuristics = ({ loggedInUserDetails, logoutUser }) => {
  const [readOnlyFlag, setReadOnlyFlag] = useState(false);
  const [showTaskAssignedTo, setShowTaskAssignedTo] = useState(true);
  const [workDoneCount, setWorkDoneCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPaginateSize, setTotalPaginateSize] = useState(0);
  const [notificationState, setNotificationState] = useState(notification);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [exhaustedDataMessage, setExhaustedDataMessage] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [heuristicsFlag, setHeuristicsFlag] = useState("");

  const onShowTaskAssignedToCheckedChange = (checked) => {
    if (checked) {
      setShowTaskAssignedTo(true);
    } else {
      setShowTaskAssignedTo(false);
    }
  };

  useEffect(() => {
    getFakeCxoHeuristicsData();
  }, [showTaskAssignedTo, page, heuristicsFlag]);

  const handleResponseChange = (e, index) => {
    setData((prevData) => {
      let tempData = prevData.slice();
      tempData[index]["response"] = e.target.value;

      return tempData;
    });
  };

  const getFakeCxoHeuristicsData = async () => {
    try {
      setLoading(true);
      let data = await axios.get(
        url.getFakeCxoHeuristicsData +
          `?email=${loggedInUserDetails.email}&role=${loggedInUserDetails.role}&showTaskAssignedTo=${showTaskAssignedTo}&page=${
            page - 1
          }&heuristicsFlag=${heuristicsFlag}`
      );

      if (!data.data.message) {
        for (let iterate = 0; iterate < data.data.data.length; iterate++) {
          data.data.data[iterate]["response"] = "";
          data.data.data[iterate]["enriched_profile"] = "https://app-next.findem.ai/profiles/" + data.data.data[iterate]["ref"];
        }

        setExhaustedDataMessage("");
        setData(data.data.data);
        setWorkDoneCount(data.data.workDone);
      } else {
        setData([]);
        setExhaustedDataMessage(data.data.message);
      }

      setReadOnlyFlag(data.data.readOnly);
      setTotalPaginateSize(data.data.totalPaginateSize);
    } catch (error) {
      setNotificationState({
        show: true,
        variant: "error",
        message: "Couldn't fetch data",
        errorMessage: error.message,
      });
      setData([]);
      setTotalPaginateSize(0);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitClick = (e, index) => {
    setActiveIndex(index);
    setShowConfirmationModal(true);
  };

  const handleCloseConfirmationModal = (e) => {
    setShowConfirmationModal(false);
  };

  const handleSubmitData = async (e) => {
    updateFakeCxoHeuristicsData();
  };

  const updateFakeCxoHeuristicsData = async () => {
    try {
      setLoading(true);
      let payload = {
        user_id: loggedInUserDetails._id,
        data_id: data[activeIndex]._id,
        ref: data[activeIndex].ref,
        userResponse: data[activeIndex]["response"],
        flag: data[activeIndex].flag,
      };
      let updateResponse = await axios.post(url.updateFakeCxoHeuristicsData, payload);

      setNotificationState({
        show: true,
        variant: "success",
        message: "Successfully updated",
        errorMessage: null,
      });
    } catch (error) {
      setNotificationState({
        show: true,
        variant: "error",
        message: "Update failed",
        errorMessage: error.message,
      });
    } finally {
      setLoading(false);
      getFakeCxoHeuristicsData();
      handleCloseConfirmationModal();
    }
  };

  const handlePageChange = (e, value) => {
    setPage(value);
  };

  const handleNotificationAlertClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationState({
      show: false,
      variant: null,
      message: null,
      errorMessage: null,
    });
  };

  const handleHeuristicsFlagChange = (e) => {
    setHeuristicsFlag(e.target.value);
  };

  return (
    <div>
      <Header loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
      {readOnlyFlag ? <h6 className="text-center mt-5">No tasks assigned | Read only mode</h6> : null}
      {loggedInUserDetails.role === "admin" ? (
        <h6 className="text-center">
          <Switch
            checkedChildren="Show all"
            unCheckedChildren="Show only assigned"
            className="mx-5"
            defaultChecked
            onChange={onShowTaskAssignedToCheckedChange}
          />
        </h6>
      ) : null}
      <TabsHomePage />
      <div className="pe-5 ps-5">
        <div className="mt-4 mb-4 total-data-container">
          <div className="row h-100 w-100">
            <div className="m-3 d-flex flex-column justify-content-center align-items-center">
              {!readOnlyFlag ? <p>Work done for today in Fake cxo heuristics: {workDoneCount}</p> : null}
            </div>

            {!loading ? (
              <div className="">
                {data.length === 0 ? (
                  <p className="text-center">No data to show</p>
                ) : exhaustedDataMessage.length > 0 ? (
                  <p className="text-center">{exhaustedDataMessage}</p>
                ) : (
                  <Stack spacing={4} justifyContent="center" alignItems="center">
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <b>Enriched Profile</b>
                            </TableCell>
                            <TableCell>
                              <b>Response</b>
                            </TableCell>
                            <TableCell>
                              <FormControl sx={{ minWidth: 200 }} variant="standard">
                                <InputLabel id="heuristicFlag">Heuristic</InputLabel>
                                <Select
                                  labelId="heuristicFlag"
                                  id="heuristics-flag"
                                  value={heuristicsFlag}
                                  label="Age"
                                  onChange={handleHeuristicsFlagChange}>
                                  <MenuItem value="">
                                    <em>All (default)</em>
                                  </MenuItem>
                                  <MenuItem value={"heuristic1"}>1</MenuItem>
                                  <MenuItem value={"heuristic2"}>2</MenuItem>
                                  <MenuItem value={"heuristic4_modified"}>heuristic4_modified</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <b>Action</b>
                            </TableCell>
                            {loggedInUserDetails.role === "admin" ? (
                              <TableCell>
                                <b>Assigned To</b>
                              </TableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                <Link href={row.enriched_profile} target="_blank">
                                  {row.ref}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <FormControl sx={{ width: 300 }} size="small">
                                  <InputLabel id="demo-select-small-label">Select response</InputLabel>
                                  <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={row?.response || ""}
                                    label={"Select response"}
                                    onChange={(e) => handleResponseChange(e, index)}>
                                    <MenuItem value="fake_cxo_profile">Fake</MenuItem>
                                    <MenuItem value="not_fake_cxo_profile">Not Fake</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell>{row.flag}</TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  disabled={!data[index]?.["response"].length || readOnlyFlag}
                                  onClick={(e) => handleSubmitClick(e, index)}>
                                  Submit
                                </Button>
                              </TableCell>
                              {loggedInUserDetails.role === "admin" ? <TableCell>{row?.taskAssignedTo || ""}</TableCell> : null}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Pagination count={Math.ceil(totalPaginateSize / 20)} page={page} onChange={handlePageChange} />
                  </Stack>
                )}
              </div>
            ) : (
              <Spin className="text-center" tip="Loading..." />
            )}
          </div>
        </div>
        <Dialog
          open={showConfirmationModal}
          onClose={handleCloseConfirmationModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{"Confirm your changes"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Mark{" "}
              {
                <Link href={data[activeIndex]?.enriched_profile} target="_blank">
                  {data[activeIndex]?.["ref"]}
                </Link>
              }{" "}
              as{" "}
              <em>
                <b>{data[activeIndex]?.["response"] === "fake_cxo_profile" ? "Fake" : "Not Fake"}</b>
              </em>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmationModal}>Close</Button>
            <Button onClick={handleSubmitData}>Submit</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={notificationState?.show || false}
          autoHideDuration={6000}
          onClose={handleNotificationAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert onClose={handleNotificationAlertClose} severity={notificationState?.variant || "success"} sx={{ width: "100%" }}>
            {notificationState?.message || ""} | {notificationState?.errorMessage || ""}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default FakeCxoHeuristics;
