import { createTheme } from "@mui/material/styles";
import baseTheme from "./baseTheme";

const darkTheme = createTheme({
    ...baseTheme,
    palette: {
        mode: "dark",
        primary: {
            main: "#90caf9", // Dark mode main primary color (light blue, to contrast with dark background)
            light: "#bbdefb", // Lighter shade for dark mode (even lighter blue)
            dark: "#42a5f5", // Darker shade of primary for dark mode (similar to light mode's light color)
        },
        secondary: {
            main: "#FFAB00", // Same yellow
        },
        background: {
            default: "#121212", // Dark background
            paper: "#121212", // Dark background
            secondary: "#161A1D", // a bit lighter then default
            light: "#31393F", // lighter then Dark background
        },
        text: {
            primary: "#B5C2CF", // main light text
            dark: "#1D2125", // darker then primary
            secondary: "#B3B3B3",
        },
        common: {
            black: "#000",
            white: "#fff",
        },
        warning: {
            main: "#ed6c02",
            light: "#ff9800",
            dark: "#e65100",
        },
        success: {
            main: "#2e7d32",
            light: "#4caf50",
            dark: "#1b5e20",
        },
        error: {
            main: "#d32f2f",
            light: "#ef5350",
            dark: "#c62828",
        },
        divider: "rgba(255, 255, 255, 0.12)",
        profileCreatorForm: {
            main: "#e3f2fd",
            workexp: "#fce4ec",
            company: "#c8e6c9",
            projects: "#a5d6a7",
            schoolInfo: "#fff176",
            location: "#ffccbc",
            duration: "#d7ccc8",
            ethnicity: "#f8bbd0",
            roleAndGroup: "#81c784",
            education: "#bbdefb",
            schoolInfo: "#fff59d",
            location: "#ffe082",
            duration: "#bcaaa4",
            educationDetails: "#d1c4e9",
            degree: "#b39ddb",
            major: "#7e57c2",
            accolades: "#ffcc80",
            awards: "#ffb74d",
            endorsements: "#ffa726",
        },
        border: {
            primary: "1px solid #616161", // Dark mode border (lighter gray)
            thick: "2px solid #757575", // Dark mode thick border (gray)
            et: "3px solid #9E9E9E",
        },
        action: {
            active: "#90caf9", // Active state color for dark mode
            hover: "rgba(144, 202, 249, 0.08)", // Hover state color
            selected: "rgba(144, 202, 249, 0.16)", // Selected state color
            disabled: "rgba(255, 255, 255, 0.5)", // Disabled state color
            disabledBackground: "rgba(255, 255, 255, 0.12)", // Background for disabled items
            focus: "rgba(144, 202, 249, 0.12)", // Focus state color
        },
        monacoTheme: "vs-dark",
    },
});

export default darkTheme;
