import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useContext, useState } from "react";
import UserContext from "../../../UserContext";
import { message } from "antd";
import axios from "axios";
import { url } from "../../../url";

const OptOutByDomain = () => {
    const loggedInUserDetails = useContext(UserContext);
    const [domain, setDomain] = useState("");
    const [alertBoxOpen, setAlertBoxOpen] = useState(false);
    const handleDomainChange = (event) => {
        setDomain(event.target.value);

    };

    const handleAlertBoxClose = () => {
        setAlertBoxOpen(false);
    };

    const handleSubmit = () => {
        setAlertBoxOpen(true);
    };

    const handleSendRequest = async () => {
        try {
            setAlertBoxOpen(false);
            const response = await axios.post(url.optOutByDomain, {
                domain: domain,
                user_id: loggedInUserDetails._id,
            });
            
            message.success(response.data.data.message);
        } catch (error) {
            if(error?.response?.status == 409){
                message.error(error.response?.data?.data?.message)
                return
            }
            message.error("Failed to opt out current domain!");
        }
    };

    return (
        <Paper elevation={3} sx={{ height: "100%", padding: "2rem" }}>
            <Box marginLeft={"auto"} marginRight={"auto"} width={"50%"} padding={"2rem"} boxShadow={"0px 4px 20px rgba(0, 0, 0, 0.25)"}>
                <Box margin={"0 auto"} width={"40%"} display={"flex"} flexDirection={"column"}>
                    <TextField
                        value={domain}
                        label="Domain"
                        helperText="Note: Please enter a valid domain."
                        onChange={handleDomainChange}
                    />
                    <Button
                        disabled={domain.length == 0}
                        onClick={handleSubmit}
                        variant="contained"
                        sx={{ marginTop: "1rem", width: "60%", alignSelf: "center" }}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>
            <Dialog sx={{ marginTop: "-20rem" }} open={alertBoxOpen} onClose={handleAlertBoxClose}>
                <DialogTitle>{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The profiles linked to the domain <strong style={{ color: "red" }}>{domain}</strong> will be permanently removed
                        from our databases.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleAlertBoxClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleSendRequest} color="error" variant="contained" endIcon={<SendIcon />} autoFocus>
                        Send Request
                    </Button>
                </DialogActions>
            </Dialog>
            
        </Paper>
    );
};

export default OptOutByDomain;
