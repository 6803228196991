import { Paper, Button, Box, Typography } from "@mui/material";
import Header from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";
import { url } from "../../url";
import { useEffect, useState } from "react";
import { Spin, Switch, message } from "antd";
import { Pagination } from 'antd';
import axios from "axios";
import { SubmitModal } from "./SubmitModal";
import { AltLinkTable } from "./AltLinkTable";
import FilterListIcon from '@mui/icons-material/FilterList';
import { FiltersModal } from "./FiltersModal";
import { checkPermission, DRA_MODULES } from "../utils/rbacUtils";

export const ENRICHED_PROFILE_LINK_PREFIX = "https://app-next.findem.ai/profiles";
export const SEARCH_LINK_PREFIX = "https://app-next.findem.ai/searches";
export const LINKEDIN_PREFIX = "https://www.linkedin.com/in";


export const AltLinkFinder = ({
    loggedInUserDetails,
    logoutUser
}) => {
    const [data, setData] = useState([]);
    const [customersData, setCustomersData] = useState({ });

    const [isLoadingData, setIsLoadingData] = useState(false);
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [workDoneCountToday, setWorkDoneCountToday] = useState("-");

    const [page, setPage] = useState(0);

    // filter states
    const [filtersValues, setFiltersValues] = useState({
        ignoreAssignedTo: true,
        isCustomerIcp: true,
        selectedSources: [],
        selectedCompanyDetails: undefined,
        showOnlyProfilesWithAlternativeSuggestions: false
    });

    // modal states
    const [submitModalDetails, setSubmitModalDetails] = useState({
        data: {},
        isOpen: false
    });
    const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

    // getAltLinkFinder api declaration
    const getAltLinkFinderData = async (page, filtersValues) => {
        const { ignoreAssignedTo, isCustomerIcp, selectedSources, selectedCompanyDetails, showOnlyProfilesWithAlternativeSuggestions } = filtersValues;
        console.log("get data payload :", selectedCompanyDetails);
        const accountId = selectedCompanyDetails?.accountId ?? null;
        const ignore_assigned_to = checkPermission(loggedInUserDetails, DRA_MODULES.assignWork).update ? ignoreAssignedTo : false;
        const body = {
            page,
            assigned_to: loggedInUserDetails.email,
            ignore_assigned_to: ignore_assigned_to,
            is_customer_icp: isCustomerIcp,
            source: selectedSources,
            account_id: accountId,
            show_only_profiles_with_alternative_suggestions: showOnlyProfilesWithAlternativeSuggestions,
            research_user_id: loggedInUserDetails._id
        }

        const response = await axios.post(url.getAltLinkFinderData, body);

        return response;
    }

    // wrapper for getData - has side effects, more logic etc
    const getAltLinkFinderDataWrapper = async (page, filtersValues) => {
        try {
            const response = await getAltLinkFinderData(page, filtersValues);

            const data = response?.data?.data?.map((datum, index) => {
                datum["isDisabled"] = true;
                datum["addedAltUid"] = null;
                // alt_uid can be null
                // datum["alt_uid"]?.forEach((alt_uid) => {
                //     alt_uid["api_label"] = alt_uid["label"];
                //     alt_uid["label"] = alt_uid["uid"];
                // });

                return datum;
            });
            if (data?.length === 0) {
                message.info("Fetched all data with the filters applied");
            }
            setData(data);
            setTotalDocuments(response?.data?.total_documents);
            setWorkDoneCountToday(response?.data?.work_done_count_today);
            setPage(page + 1);
        } catch(error) {
            console.log(error);
            message.error("Failed to fetch alt link data");
        } finally {
        }
    }

    const getCustomersData = async () => {
        const response = await axios.get(url.customersDataPilot);

        return response;
    }


    const wrapperCustomersData = async () => {
        const responseCustomers = await getCustomersData();
        setCustomersData(responseCustomers?.data?.response ?? {});

        return responseCustomers;
    }

    const initializeGetData = async () => {
        try {
            setIsLoadingData(true);
            await wrapperCustomersData();
            await getAltLinkFinderDataWrapper(page, filtersValues);
        } catch(error) {
            console.log(error);
            message.error(error.msg || "Error in fetching data");
        } finally {
            setIsLoadingData(false);
        }
        
    }

    useEffect(() => {
        try {
            if (loggedInUserDetails.email) {
                initializeGetData();
            }
        } catch(error) {
            console.log(error);
            message.error(error.msg || "Failed to initialize data");
        } finally {

        }
    }, []);

    const handlePageChange = async (page) => {
        try {
            setIsLoadingData(true);
            await getAltLinkFinderDataWrapper(page - 1, filtersValues);
        } catch (error) {
            console.log(error);
            message.error(error.msg || "Failed to increment page");
        } finally {
            setIsLoadingData(false);
        }
    }

    const handleSelectionAltLink = (index) => (newValue, actionMeta) => {
        const addedAltUid = newValue?.uid || newValue?.value?.trim() || null;
        console.log("click event :",newValue, actionMeta, index, addedAltUid);
        
        setData((prevValues) => prevValues.map((value, innerIndex) => {
            // matched row
            if (index === innerIndex) {
                return Object.assign({}, value, { addedAltUid, isDisabled: !addedAltUid?.length });
            }

            return value;
        }));
    }

    const handleOpenSubmitModal = (e) => {
        const row = e?.target?.getAttribute("data-row");
        setSubmitModalDetails({isOpen: true, data: data[row]});
    }
    
    const handleCloseSubmitModal = () => {
        setSubmitModalDetails({ isOpen: false, data: {} });
    }

    const handleCloseFiltersModal = () => {
        setIsFiltersModalOpen(false);
    }

    const handleOpenFiltersModal = () => {
        setIsFiltersModalOpen(true);
    }

    const handleSubmit = async (comment) => {
        try {
            setIsLoadingData(true);
            const {
                _id, 
                ref,
                dead_uid,
                source,
                hide,
                status, 
                assigned_to,
                addedAltUid,
                company_name,
                account_id,
                profile_ref,
                owner_id,
                icp_id,
            } = submitModalDetails["data"];

            const updateAltLinkDataPayload = {
                comment,
                _id,
                ref,
                dead_uid,
                added_alt_uid: addedAltUid,
                user_email: loggedInUserDetails.email,
                company_name: company_name,
                account_id: account_id
            };

            const shortlistLink = `${SEARCH_LINK_PREFIX}/${icp_id}?joid=${owner_id}&redirect_joid=${owner_id}`;
            const epLink = `${ENRICHED_PROFILE_LINK_PREFIX}/${profile_ref}?state=/searches/${icp_id}&icpid=${icp_id}&joid=${owner_id}&aId=${account_id}`;

            const auditLogData = {
                eventName: "alt_link_finder",
                eventType: "save",
                userId: loggedInUserDetails._id,
                eventMetaData: {
                    candidateId: profile_ref,
                    customerName: company_name ?? "not specified",
                    ownerId: owner_id,
                    accountId: account_id,
                    icpId: icp_id,
                    comment,
                    shortlistLink,
                    epLink,
                    source
                },
                oldRecord: {},
                newRecord: {
                    altLinkFinderData: submitModalDetails["data"]
                }
            }

            const body = {
                update_alt_link_data_payload: updateAltLinkDataPayload,
                audit_log_data: auditLogData
            };

            await axios.post(url.updateAltLinkData, body);
            message.success("Success");
            handleCloseSubmitModal();
            await getAltLinkFinderDataWrapper(0, filtersValues);
        } catch (error) {
            console.error(error);

            let errorMessage = 'An error occurred';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }

            message.error(errorMessage);
        } finally {
            setIsLoadingData(false);
        }
    }

    // const onShowTaskAssignedToCheckedChange = async (checked) => {
    //     const ownerIdMapCompanyName = buildOwnerIdMapCompanyName(customersData);
    //     await getAltLinkFinderDataWrapper(0, checked, ownerIdMapCompanyName);
    //     setIgnoreAssignedTo(checked);
    // };

    const handleApply = async (filterValues) => {
        try {
            setIsLoadingData(true);
            await getAltLinkFinderDataWrapper(0, filterValues);
    
            setFiltersValues(filterValues);
            handleCloseFiltersModal();
        } catch (error) {
            console.log(error);
            message.error(error.msg || "An Error occurred");
        } finally {
            setIsLoadingData(false);
        }
    }


    return (
        <div>
            <Header
                loggedInUserDetails={loggedInUserDetails}
                logoutUser={logoutUser}
            />
            <TabsHomePage />
            
            <Box sx={{my: 2, display: "flex", justifyContent: "center", gap: 4, alignItems: "center"}}>
                {!isLoadingData &&
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={handleOpenFiltersModal}
                        startIcon={<FilterListIcon />}
                    >
                        Filters
                    </Button>
                }
                <Typography>
                    {`Work done count for today: ${workDoneCountToday}`}
                </Typography>
            </Box>
            <Paper
                sx={{ mt: 3, mx: 3, p: 0 }}
                elevation={5}
                style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
            >
                {isLoadingData ? 
                    <Spin /> :
                    <AltLinkTable 
                        data={data}
                        page={page}
                        handleSelectionAltLink={handleSelectionAltLink}
                        handleOpenSubmitModal={handleOpenSubmitModal}
                    />
                }
                <Pagination simple
                    onChange={handlePageChange}
                    current={page}
                    total={totalDocuments}
                    defaultPageSize={10}
                    className="my-5"
                />
            </Paper>
            {submitModalDetails.isOpen && 
                <SubmitModal
                    submitModalDetails={submitModalDetails}
                    handleCloseSubmitModal={handleCloseSubmitModal}
                    handleSubmit={handleSubmit}
                />
            }
            {isFiltersModalOpen && 
                <FiltersModal
                    loggedInUserDetails={loggedInUserDetails}
                    filtersValues={filtersValues}
                    isFiltersModalOpen={isFiltersModalOpen}
                    handleCloseFiltersModal={handleCloseFiltersModal}
                    handleApply={handleApply}
                    customersData={customersData}
                />
            }
        </div>
    );
}