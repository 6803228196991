import { Card, CardHeader, Divider, Typography } from "@mui/material";
import ProjectForm from "./ProjectForm";
import CertificationForm from "./CertificationForm";
import VolunteerExperienceFrom from "./VolunteerExperienceForm";
import LanguageForm from "./LanguageForm";

const AdditionalInfoForm = ({ fieldPath, name, form }) => {
    return (
        <Card variant="outlined" sx={{ p: 2, backgroundColor: "profileCreatorForm.main" }}>
            <CardHeader sx={{ p: 0 }} title={<Typography variant="body1">Additional Info</Typography>} />
            <Divider sx={{ mb: 3 }} />
            <ProjectForm fieldPath={[...fieldPath, "projects"]} name={[...name, "projects"]} form={form} />
            <CertificationForm fieldPath={[...fieldPath, "certifications"]} name={[...name, "certifications"]} form={form} />
            <VolunteerExperienceFrom
                fieldPath={[...fieldPath, "volunteer_experience"]}
                name={[...name, "volunteer_experience"]}
                form={form}
            />
            <LanguageForm fieldPath={[...fieldPath, "languages"]} name={[...name, "languages"]} form={form} />
        </Card>
    );
};

export default AdditionalInfoForm;
