import React from "react";
// import "./index.css";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, DatePicker } from "antd";

import dayjs from "dayjs";
import { Card, CardHeader, Divider, IconButton, Typography } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const CertificationForm = ({ fieldPath, name, form }) => {
    return (
        <Card variant="outlined" sx={{ p: 2, mt: 2, backgroundColor: "profileCreatorForm.certifications" }}>
            <CardHeader sx={{ p: 0 }} title={<Typography variant="body1">Certifications</Typography>} />
            <Divider sx={{ mb: 3 }} />
            <Form.List name={name}>
                {(certificationFields, { add: addCertification, remove: removeCertificate }) => (
                    <>
                        {certificationFields.map((certificationField) => (
                            <Card variant="outlined" sx={{ p: 2, mt: 2, backgroundColor: "profileCreatorForm.certifications" }}>
                                <CardHeader
                                    action={
                                        <IconButton onClick={() => removeCertificate(certificationField.name)} aria-label="settings">
                                            <RemoveCircleIcon />
                                        </IconButton>
                                    }
                                    sx={{ p: 0 }}
                                    title={<Typography variant="body1">{`Certificate ${certificationField.key + 1}`}</Typography>}
                                />
                                <Divider sx={{ mb: 3 }} />

                                {/* Other Fields of projects under workexp */}
                                <Form.Item
                                    name={[certificationField.name, "name"]}
                                    fieldKey={[certificationField.fieldKey, "name"]}
                                    label="Name"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item name={[certificationField.name, "authority"]} label={"Authority"}>
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name={[certificationField.name, "date"]}
                                    label={"Date"}
                                    normalize={(value) => (value && !dayjs.isDayjs(value) ? dayjs(value) : value)}
                                >
                                    <DatePicker allowClear />
                                </Form.Item>

                                <Form.Item
                                    name={[certificationField.name, "description"]}
                                    fieldKey={[certificationField.fieldKey, "description"]}
                                    label="Description"
                                >
                                    <Input.TextArea />
                                </Form.Item>
                            </Card>
                        ))}

                        <Button type="dashed" onClick={() => addCertification()} block icon={<PlusOutlined />}>
                            Add Certification
                        </Button>
                    </>
                )}
            </Form.List>
        </Card>
    );
};

export default CertificationForm;
