import { Button, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { url } from "../../../url";
import { useEffect, useState } from "react";
import { Box, Divider, FormControl, FormLabel, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { DIRECT_UPDATE_STATUS_OPTIONS } from "../../DirectUpdate/direct-update.utils";
const dayjs = require("dayjs");

const ALL_USER_GROUPS = { name: "" };

const Reports = ({ loggedInUserDetails, logoutUser }) => {
    const [dateRange, setDateRange] = useState({ from: dayjs().startOf("day"), to: dayjs() });
    const [userGroupOptions, setUserGroupOption] = useState({ data: [], isLoading: true });
    const [directeUpdateStatus, setDirectUpdateStatus] = useState("");
    const [selectedUserGroup, setSelectedUserGroup] = useState(ALL_USER_GROUPS);
    const [errorMessage, setErrorMessage] = useState("");

    const getAllAuditLogs = async () => {
        window.open(`${url.backend_url}/getAuditLogs?dateRange=${JSON.stringify(dateRange)}&userGroupName=${selectedUserGroup.name}`);
    };

    const getCopilotAuditLogs = async () => {
        window.open(
            `${url.backend_url}/getAuditLogs?event=copilot&dateRange=${JSON.stringify(dateRange)}&userGroupName=${selectedUserGroup.name}`
        );
    };

    const getExcludeCopilotAuditLogs = async () => {
        window.open(
            `${url.backend_url}/getAuditLogs?event=exclude_copilot&dateRange=${JSON.stringify(dateRange)}&userGroupName=${
                selectedUserGroup.name
            }`
        );
    };

    const downloadDirectUpdateLogs = async () => {
        window.open(
            `${url.backend_url}/getAuditLogs?event=direct_update&dateRange=${JSON.stringify(
                dateRange
            )}&status=${directeUpdateStatus}&userGroupName=${selectedUserGroup.name}`
        );
    };

    const handleDateChange = (field) => (value) => {
        const newDateRange = { ...dateRange, [field]: value };
        setDateRange(newDateRange);
        validateDateRange(newDateRange.from, newDateRange.to);
    };

    const validateDateRange = (from, to) => {
        if (from && to) {
            const diffMonths = dayjs(to).diff(dayjs(from), "month", true);
            if (diffMonths > 1) {
                setErrorMessage("The date range should not exceed 1 month.");
                return true;
            } else if (diffMonths < 0) {
                setErrorMessage("The date range is not valid.");
                return false;
            } else {
                setErrorMessage("");
                return false;
            }
        }

        return false;
    };
    const fetchUserGroupOptions = async () => {
        try {
            const response = await axios.get(url.getUserGroups, { params: { fields: "name" } });
            setUserGroupOption({ data: response?.data?.data, isLoading: false });
        } catch (error) {
            console.log("Failed to get user group:", error);
            message.error("Failed to get user groups!");
            setUserGroupOption({ ...userGroupOptions, isLoading: false });
        }
    };
    const handleUserGroupChange = (event) => {
        setSelectedUserGroup(event.target.value);
    };

    const handleDirectUpdateStatusChange = (event) => {
        setDirectUpdateStatus(event.target.value);
    };

    useEffect(() => {
        fetchUserGroupOptions();
    }, []);

    return (
        <>
            <div className="mx-5" style={{ paddingBottom: "3rem" }}>
                <div className="pe-3 total-data-container" style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "100%" }}>
                        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center", padding: "0.5rem 1rem" }}>
                            <div style={{ marginBottom: "30px", marginTop: "10px" }}>
                                <FormLabel sx={{ mb: "0.6rem", fontWeight: "bold", color: "#1976D2" }}>Date Range</FormLabel>
                                <Box display="flex" alignItems="center" flexDirection="column">
                                    <Box display="flex" mb={2} justifyContent={"space-between"} alignItems={"center"}>
                                        <DateTimePicker
                                            format="DD/MM/YYYY hh:mm A"
                                            sx={{ width: "45%" }}
                                            label="From"
                                            value={dateRange.from}
                                            onChange={handleDateChange("from")}
                                            disableFuture
                                        />
                                        <Divider sx={(theme) => ({ width: theme.spacing(1.5) })} />
                                        <DateTimePicker
                                            format="DD/MM/YYYY hh:mm A"
                                            sx={{ width: "45%" }}
                                            label="To"
                                            value={dateRange.to}
                                            onChange={handleDateChange("to")}
                                            disableFuture
                                        />
                                    </Box>
                                    {errorMessage && (
                                        <Typography color="error" variant="body2">
                                            {errorMessage}
                                        </Typography>
                                    )}
                                </Box>
                            </div>
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ height: "auto !important" }}></Divider>
                            <FormControl sx={{ minWidth: 300 }}>
                                <InputLabel>User Group</InputLabel>
                                <Select value={selectedUserGroup} label="User Group" onChange={handleUserGroupChange}>
                                    <MenuItem value={ALL_USER_GROUPS}>ALL</MenuItem>
                                    {userGroupOptions.data?.map((group) => {
                                        return <MenuItem value={group}>{group.name}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                            <Divider orientation="vertical" flexItem></Divider>
                            <FormControl sx={(theme) => ({ width: theme.spacing(30) })}>
                                <InputLabel id="direct-update-status">Direct Update Status</InputLabel>
                                <Select
                                    labelId="direct-update-status"
                                    value={directeUpdateStatus}
                                    label="Direct Update Status"
                                    onChange={handleDirectUpdateStatusChange}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {DIRECT_UPDATE_STATUS_OPTIONS.map((option, index) => {
                                        return (
                                            <MenuItem key={index} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Divider sx={{ height: "auto !important" }}></Divider>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center", margin: "2rem" }}>
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                size="large"
                                onClick={getAllAuditLogs}
                                disabled={errorMessage.length > 0}
                            >
                                Download all audit logs
                            </Button>
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                size="large"
                                onClick={getCopilotAuditLogs}
                                disabled={errorMessage.length > 0}
                            >
                                Download only copilot audit logs
                            </Button>
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                size="large"
                                onClick={downloadDirectUpdateLogs}
                                disabled={errorMessage.length > 0}
                            >
                                Download only Direct Update logs
                            </Button>
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                size="large"
                                onClick={getExcludeCopilotAuditLogs}
                                disabled={errorMessage.length > 0}
                            >
                                Download exclude copilot audit logs
                            </Button>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Reports;
