export const sourceNames = [
    "Behance",
    "Artstation",
    "Dribbble",
    "LinkedIn",
    "Twitter",
    "Facebook",
    "GitHub",
    "StackOverflow",
    "Instagram",
    "Kaggle",
    "LeetCode",
    "CodeForces",
    "CodeChef",
    "Quora",
    "Scholar",
];

