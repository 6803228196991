import { Editor } from "@monaco-editor/react";
import { useTheme } from "@mui/material";
import React from "react";
import { parseEditorData } from "./editor-utils";

const DataEditor = ({ editorRef, editorData, readOnly, language }) => {
    const stringyValue = parseEditorData(language, editorData);
    const theme = useTheme();

    const handleEditorDidMount = (editor, monaco) => {
        editorRef.current = editor;
    };


    return (
        <Editor
            language={language}
            theme={theme.palette.monacoTheme}
            value={stringyValue}
            options={{
                readOnly: readOnly,
                minimap: { enabled: true },
                fontSize: 14,
                formatOnType: true,
                scrollBeyondLastLine: false,
                automaticLayout: true,
            }}
            onMount={handleEditorDidMount}
        />
    );
};

export default DataEditor;
