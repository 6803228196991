import EditNoteIcon from "@mui/icons-material/EditNote";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Alert, Box, Button, CircularProgress } from "@mui/material";
import { Divider, message } from "antd";
import { useEffect, useRef, useState } from "react";
import DataEditor from "../../Common/editor/DataEditor";
import { EDITOR_LANGUAGES } from "../../Common/editor/editor-utils";

const TextTypeMenu = {
    raw: {
        label: "View Raw Text",
    },
    format: {
        label: "View Formatted Text",
    },
};

const LeftPane = ({ uids, setUids }) => {
    const [fileData, setFileData] = useState({ name: "", isLoading: false, isLargeToShow: false });
    const [visibleContent, setVisibleContent] = useState();
    const [rawData, setRawData] = useState();
    const [textType, setTextType] = useState("raw");
    const [textTypeOpen, setTextTypeOpen] = useState(false);
    const editorRef = useRef(null);
    const [editEnabled, setEditEnabled] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleFileUpload = (event) => {
        try {
            const file = event.target.files[0];
            if (file && file.type === "text/csv") {
                setFileData({ name: "", content: "", isLoading: true });
                const reader = new FileReader();
                reader.onload = (e) => {
                    const content = e.target.result;
                    setFileData({
                        name: file.name,
                        isLoading: false,
                        isLargeToShow: content.length > 50000,
                    });
                    setRawData(content);
                    const uidArray = content
                        .split(",")
                        .map((uid) => uid.trim())
                        .filter((uid) => uid.length > 0);
                    setUids([...new Set(uidArray)]);
                    setVisibleContent(content);
                };
                reader.readAsText(file);
            }
            setTextType("raw");
            event.target.value = null;
        } catch (error) {
            setFileData({ ...fileData, isLoading: false });
            message.error("Unable to upload file!");
            console.log("Failed to upload file:", error);
        }
    };

    const handleTextTypePopover = () => {
        if (editEnabled) {
            setVisibleContent(rawData);
        } else {
            const text = rawData;
            const result = text.replace(/,(?!\n)/g, ",\n");
            setVisibleContent(result);
        }
    };

    const handleReset = () => {
        setFileData({ ...fileData, name: "", isLargeToShow: false });
        setRawData("");
        setVisibleContent("");
        setUids("");
    };

    const handleEditEditor = () => {
        setEditEnabled(true);
    };

    const handleSaveEditor = () => {
        if (editorRef.current) {
            // Get the current value of the editor
            const editorData = editorRef.current.getValue();
            const uidArray = editorData
                .split(",")
                .map((uid) => uid.trim())
                .filter((uid) => uid.length > 0);
            setRawData(editorData);
            setUids([...new Set(uidArray)]);
            setVisibleContent(editorData);
            setTextType("raw");
        } else {
            message(`Error while saving the editor's data!`);
        }
        setEditEnabled(false);
    };

    useEffect(() => {
        handleTextTypePopover();
    }, [editEnabled]);

    return (
        <Box
            sx={{ width: "80%", height: "100%", display: "flex", flexDirection: "column", backgroundColor: "background.paper" }}
            overflow={"auto"}
        >
            {fileData.isLargeToShow && (
                <Alert severity="warning">The file is too large to handle. You can still send the Refresh Request.</Alert>
            )}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0.5rem",
                    margin: "1px",
                    background: "white",
                    borderRadius: "3px",
                    backgroundColor: "background.paper",
                }}
            >
                <Box display={"flex"} alignItems={"center"}>
                    {/* <Box>
                        <Button
                            sx={{ textTransform: "none", fontWeight: "600", color: "#172B4D" }}
                            variant="text"
                            endIcon={<ExpandCircleDownIcon />}
                            onClick={handleTextTypePopover}
                        >
                            {TextTypeMenu?.[textType]?.label}
                        </Button>
                        <Menu
                            id="text-type-menu"
                            anchorEl={anchorEl}
                            open={textTypeOpen}
                            onClose={handleTextTypePopover}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <MenuItem data-text-type="raw" onClick={handleTextTypePopover}>
                                View Raw Text
                            </MenuItem>
                            <MenuItem data-text-type="format" onClick={handleTextTypePopover}>
                                View Formatted Text
                            </MenuItem>
                        </Menu>
                    </Box> */}
                    <Button onClick={handleReset} startIcon={<RotateLeftIcon />} type="text">
                        Reset
                    </Button>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                    <Button
                        onClick={editEnabled ? handleSaveEditor : handleEditEditor}
                        selected={editEnabled}
                        startIcon={editEnabled ? undefined : <EditNoteIcon />}
                        type="text"
                    >
                        {editEnabled ? "Save" : "Edit"}
                    </Button>
                    <Divider style={{ height: "1.5rem" }} type="vertical"></Divider>
                    <Button
                        component="label"
                        startIcon={<UploadFileIcon />}
                        endIcon={fileData.isLoading ? <CircularProgress size={20} disableShrink /> : fileData.name && `(${fileData.name})`}
                        type="text"
                    >
                        <input style={{ display: "none" }} id="file-input" type="file" accept=".csv" onChange={handleFileUpload} />
                        Upload File
                    </Button>
                </Box>
            </Box>
            <Divider style={{ margin: "0" }}></Divider>
            <DataEditor editorRef={editorRef} editorData={visibleContent} readOnly={!editEnabled} language={EDITOR_LANGUAGES.plaintext} />
        </Box>
    );
};

export default LeftPane;
