import { Paper, CircularProgress, Button, Typography } from "@mui/material";
import Header from "../../Header/Header";
import TabsHomePage from "../../Home/TabsHomePage";
import LeftPane from "./LeftPane";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { url } from "../../../url";
import Loading from "./Loading";
import RightPaneContainer from "./RightPaneContainer";
import GenericModal from "../GenericModal";
import GenericSnackbar from "./GenericSnackbar";
import Chip from "@mui/material/Chip";
import { decodeString } from "../../../util";
import MuiModal from "../../Common/MuiModal";
import dayjs from "dayjs";
import { message, Switch } from "antd";
import IcpDropdownLabel from "./IcpDropdownLabel";
import RefreshIcon from "@mui/icons-material/Refresh";

const SEARCH_LINK = "https://app-next.findem.ai/searches";
const ENRICHED_PROFILE_LINK = "https://app-next.findem.ai/profiles";
const ASSIGNMENT_CHANGE_POLL_TIME = 1000 * 60 * 5;
const FINDEM_ACCOUNTS = [
  "findem",
  "demo",
  "test",
  "accutalents",
  "greenhouseatsdemo",
  "ashbyatsdemo",
  "greenhouse",
  "rahularva",
];

const RTCopilotSearches = ({ loggedInUserDetails, logoutUser }) => {
  const [data, setData] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [peVerifiedIcpsDropdownLoading, setPeVerifiedIcpsDropdownLoading] =
  useState(false);
  const [saveNotes, setSaveNotes] = useState("");
  const scrollToTopButtonRef = useRef(null);
  const scrollableContainerRef = useRef(null);
  const [page, setPage] = useState(0);
  const [icpPage, setIcpPage] = useState(0);
  const [allProfilesLoader, setAllProfilesLoaded] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [peDropdownSelectedValue, setPeDropdownSelectedValue] = useState("");
  const [endOfPageReached, setEndOfPageReached] = useState(false);
  const [showTaskAssignedTo, setShowTaskAssignedTo] = useState(true);
  const [workDoneCount, setWorkDoneCount] = useState("-");

  // api data
  //                                                Map<any, any>
  const [customersData, setCustomersData] = useState(undefined);

  const messageRef = useRef(null);
  // export const colourOptions: readonly ColourOption[] = [
  //   { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  //   { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
  // ];
  // search states
  const searchIcpRef = useRef(null);
  const [peVerifiedIcps, setPeVerifiedIcps] = useState([]);
  const [showCustomerSearches, setShowCustomerSearches] = useState(true);
  const [showOnDemandSearches, setShowOnDemandSearches] = useState(true);
  const [showPersonalEmailsOnlyProfiles, setShowPersonalEmailsOnlyProfiles] = useState(false)
  // manual searched icp id
  const [manualSearchIcpId, setManualSearchIcpId] = useState("");

  const handleManualSearchIcpIdChange = (e) => {
    setManualSearchIcpId(e.target.value);
  }

  // FORM STATES
  const [status, setStatus] = useState("");

  // MODAL STATES
  const [openNewTaskAssignmentTriggered, setOpenNewTaskAssignmentTriggered] =
    useState(false);
  const [openStatusVerificationModal, setOpenStatusVerificationModal] =
    useState(false);

  const [snackbarDetails, setSnackbarDetails] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const [contactInfoData, setContactInfoData] = useState({
    public_emails: [],
    linkedins: [],
    sandbox_emails: [],
    golden_emails: [],
    sandbox_phones: [],
    global_phones: [],
    global_ats_emails: [],
    sandbox_ats_emails: [],
    sandbox_removed_emails: [],
    public_removed_emails: [],
    ats_info: [],
  });
  const [phoneNumbersToDisplay, setPhoneNumbersToDisplay] = useState(() => {
    let returnObject = {};
    if (data) {
      if (data["sandbox_name"] != null) {
        contactInfoData["global_phones"]?.forEach((phoneNumber) => {
          returnObject[phoneNumber] = { type: "global_phones", checked: false };
        });
        contactInfoData["sandbox_phones"]?.forEach((phoneNumber) => {
          returnObject[phoneNumber] = { type: "sandbox_phones", checked: true };
        });
      } else {
        contactInfoData["global_phones"]?.forEach((phoneNumber) => {
          returnObject[phoneNumber] = { type: "global_phones", checked: true };
        });
      }
    }

    return returnObject;
  });

  const [removedEmailsToDisplay, setRemovedEmailsToDisplay] = useState(() => {
    let returnObject = {};
    if (data) {
      if (data["sandbox_name"] != null) {
        // in sandbox show all
        contactInfoData["sandbox_removed_emails"]?.forEach((email) => {
          returnObject[email] = {
            type: "sandbox_removed_emails",
            checked: false,
            disabled: true,
          };
        });
        contactInfoData["public_removed_emails"]?.forEach((email) => {
          returnObject[email] = {
            type: "public_removed_emails",
            checked: false,
            disabled: true,
          };
        });
      } else {
        contactInfoData["public_removed_emails"]?.forEach((email) => {
          returnObject[email] = {
            type: "public_removed_emails",
            checked: false,
            disabled: true,
          };
        });
      }
    }

    return returnObject;
  });

  const [directEmailsFromATS, setDirectEmailsFromATS] = useState(() => {
    let returnObject = {};
    if (data) {
      contactInfoData["ats_info"]?.forEach((ats_info_obj) => {
        ats_info_obj?.email_address?.forEach((email) => {
          returnObject[email] = {
            type: "direct_emails_from_ats",
            checked: false,
            disabled: true,
          };
        });
      });
    }

    return returnObject;
  });

  const [emailsToDisplay, setEmailsToDisplay] = useState(() => {
    let returnObject = {};
    if (data) {
      if (data["sandbox_name"] != null) {
        // in sandbox show all
        contactInfoData["public_emails"]?.forEach((email) => {
          returnObject[email] = {
            type: "public_emails",
            checked: false,
          };
        });
        contactInfoData["global_ats_emails"]?.forEach((email) => {
          returnObject[email] = { type: "global_ats_emails", checked: false };
        });
        contactInfoData["golden_emails"]?.forEach((email) => {
          returnObject[email] = { type: "golden_emails", checked: false };
        });
        contactInfoData["sandbox_ats_emails"]?.forEach((email) => {
          returnObject[email] = { type: "sandbox_ats_emails", checked: true };
        });
        contactInfoData["sandbox_emails"]?.forEach((email) => {
          returnObject[email] = { type: "sandbox_emails", checked: true };
        });
      } else {
        contactInfoData["global_ats_emails"]?.forEach((email) => {
          returnObject[email] = { type: "global_ats_emails", checked: true };
        });
        contactInfoData["golden_emails"]?.forEach((email) => {
          returnObject[email] = { type: "golden_emails", checked: false };
        });
        contactInfoData["public_emails"]?.forEach((email) => {
          returnObject[email] = { type: "public_emails", checked: true };
        });
      }
    }

    console.log("Inside checkbox email fill : ", returnObject);
    return returnObject;
  });

  const filteredPeVerifiedIcps = useMemo(() => {
    if (!showCustomerSearches) {
      return peVerifiedIcps;
    }
    const filteredIcps = peVerifiedIcps.filter((peVerifiedIcp) => {
      if (
        !FINDEM_ACCOUNTS.some((account) =>
          peVerifiedIcp?.customer_name?.toLowerCase()?.includes(account)
        )
      ) {
        return peVerifiedIcp;
      }
    })
    .filter((peVerifiedIcp) => {
      return (showOnDemandSearches && peVerifiedIcp.icp_type !== 6) || (!showOnDemandSearches && peVerifiedIcp.icp_type === 6);
    });

    return filteredIcps;
  }, [peVerifiedIcps, showCustomerSearches, showOnDemandSearches]);

  const handleShowCustomerSearches = (checked) => {
    setShowCustomerSearches(checked);
    handleOpenSnackbar("Dont forget to click on 'Apply Changes' to see the effects applied", "info");
  };

  const pushContactInfoIntoProfiles = async(profilesData)=>{
    try {
      const payloads = {};
      profilesData.forEach((profile) => {
        const sandboxName = profile?.sandbox_name || "public_profile";
        const payload = payloads[sandboxName] ?? {
            prids: [],
            owner_id: profile?.owner_id,
            sandbox_namespace: sandboxName === "public_profile" ? null : sandboxName,
        };
        payload.prids.push(profile?.profile_id);
        payloads[sandboxName] = payload;
      });

      const response = await axios.post(url.fetchContactInfoFromMultipleSources, {
        payloads: Object.values(payloads)
      });

      const profilesWithContactInfo = profilesData.map((profileData)=>{
        return {
          ...profileData,
          contact_info: response?.data?.data?.[profileData?.profile_id] || null,
        };
      })
      return profilesWithContactInfo;
    }
    catch(error){
      message.error(error?.response?.data?.message || "Filter Failed: Unable to get contact info for profiles!")
    }
  }
  
  const handlePersonalEmailsOnly = async(event) =>{
    const newPersonalEmailsOnly = event.target.checked
    setShowPersonalEmailsOnlyProfiles(newPersonalEmailsOnly)
    setSelectedProfileId(null)
  }

  const handleShowOnDemandSearches = (checked) => {
    setShowOnDemandSearches(checked);

    // is preprocessed and customer searches is not enabled
    // preprocess can only happen if its a customer search
    if (checked === false && showCustomerSearches === false) {
      setShowCustomerSearches(true);
    }
    handleOpenSnackbar("Dont forget to click on 'Apply Changes' to see the effects applied", "info");
  }

  const handleCloseSnackbar = () => {
    setSnackbarDetails({
      open: false,
      messsage: "",
      severity: "",
    });
  };

  const handleOpenSnackbar = (message, severity) => {
    setSnackbarDetails({
      open: true,
      message,
      severity,
    });
  };

  const handleCloseStatusVerificationModal = useCallback(() => {
    setOpenStatusVerificationModal(false);
    setStatus("");
  }, []);

  const handleOpenStatusVerificationModal = useCallback(() => {
    setOpenStatusVerificationModal(true);
  }, []);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    handleOpenStatusVerificationModal();
  };

  const handleCloseNewTaskAssignmentTriggered = () => {
    setOpenNewTaskAssignmentTriggered(false);
  };

  const handleOpenNewTaskAssignmentTriggered = () => {
    setOpenNewTaskAssignmentTriggered(true);
  };

  const handleSaveNotes = (e) => {
    setSaveNotes(e.target.value);
  };

  useEffect(() => {
    fetchRtCopilotPollAssignment();
    const intervalId = setInterval(
      fetchRtCopilotPollAssignment,
      ASSIGNMENT_CHANGE_POLL_TIME
    );
    populatePeVerifiedIcpsDropdown();

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const fetchRtCopilotPollAssignment = async () => {
    console.log("Polling call");
    const response = await axios.get(url.rtCopilotPollAssignment);
    const created_at = response.data.data.created_at;

    const prev_timestamp = localStorage.getItem(
      "rt_copilot_task_assignment_timestamp"
    );

    if (!prev_timestamp) {
      // first time
      localStorage.setItem("rt_copilot_task_assignment_timestamp", created_at);
    } else if (!dayjs(prev_timestamp).isSame(dayjs(created_at))) {
      console.log(
        "local storage check ",
        dayjs(prev_timestamp).isSame(dayjs(created_at)),
        prev_timestamp,
        created_at
      );
      localStorage.setItem("rt_copilot_task_assignment_timestamp", created_at);
      handleOpenNewTaskAssignmentTriggered();
    }
  };

  const fetchPeFerifiedIcps = async (icpPage) => {
    const body = { 
      mode: "manual",
      type: showOnDemandSearches ? "ondemand" : "preprocessed",
      customer_accounts: showOnDemandSearches ? showCustomerSearches : false,
      page: icpPage,
    };

    console.log("fetch dropdown icps body: ", body);
    let response = await axios.post(url.fetchIcpsForVerification, body);

    return response;
  };

  const fetchRtWorkDone = async () => {
    let response = await axios.post(url.rtCopilotWorkDone, {
      email: loggedInUserDetails.email,
    });

    return response;
  };

  const handleIncrementIcpPage = async () => {
    try {
      setPeVerifiedIcpsDropdownLoading(true);

      const response = await fetchPeFerifiedIcps(icpPage);
      let owner_id_map_company_name;
      
      // if didnt make the initial populatePeVerifiecIcpsDropdown call then this might be empty
      if (!customersData) {
        owner_id_map_company_name = await wrapperCustomersData();
        setCustomersData(owner_id_map_company_name);
      }
      let icpsData = [];
      
      if (response?.data?.data?.icps) {
        icpsData = buildPeVerifiedIcpsState(response.data.data.icps, customersData ?? owner_id_map_company_name);
      }

      const totalPages = response?.data?.data?.total_pages;
      if (icpPage === totalPages - 1) {
        handleOpenSnackbar("Fetched all ICPS with the current filters applied", "info");
      }

      setPeVerifiedIcps((prevState) => prevState.concat(icpsData));
      setIcpPage((page) => page + 1);
    } catch (e) {
      console.error(e);
    } finally {
      setPeVerifiedIcpsDropdownLoading(false);
    }
  }

  const buildPeVerifiedIcpsState = (icps, owner_id_map_company_name) => {
    const peVerifiedIcpsState = [];

    for (let data of icps) {
      const icp_id = data["id"];
      const name = data["name"];
      const owner_id = data["owner_id"];
      const unverified_profile_count = data["unverified_profile_count"];
      const icpType = data["type"];
      const mappedIcpType = icpType == 6 ? 'Preprocessing' : 'On Demand';

      const { company_name, account_id } =
        owner_id_map_company_name.get(owner_id) ?? {};

      const object = {
        label: (
          <IcpDropdownLabel
            companyName={
              owner_id_map_company_name.get(owner_id)?.["company_name"] ??
              "Unknown Customer"
            }
            name={decodeString(name)}
            unverifiedProfileCount={unverified_profile_count}
            icpId={icp_id}
            icpType={mappedIcpType}
          />
        ),
        value: icp_id,
        icp_id,
        name,
        owner_id,
        account_id,
        // used in custom filtering logic
        decoded_string: decodeString(name),
        customer_name: company_name,
        icp_type: icpType,
        mapped_icp_type: mappedIcpType,
        color: "#030303",
      };

      peVerifiedIcpsState.push(object);
    }

    return peVerifiedIcpsState;
  }

  const wrapperCustomersData = async () => {
    const response_customers = await axios.get(url.customersDataPilot);
    const account_id_map_company_details = response_customers.data.response;
    const owner_id_map_company_name = new Map();

    for (let account_id in account_id_map_company_details) {
      const { users, company_name } =
        account_id_map_company_details[account_id];

      for (let user of users) {
        owner_id_map_company_name.set(user, { company_name, account_id });
      }
    }

    return owner_id_map_company_name;
  }

  // call this for initial/populating from start only
  // initial call to populate dropdown
  const populatePeVerifiedIcpsDropdown = async () => {
    try {
      setIsLoading(true);
      setPeVerifiedIcps([]);
      setData([]);
      setPeDropdownSelectedValue({});
      setPeVerifiedIcpsDropdownLoading(true);
      let response = await fetchPeFerifiedIcps(0);
      let workDoneResponse = await fetchRtWorkDone();
      const owner_id_map_company_name = await wrapperCustomersData();

      // handle for empty object on last page
      let peVerifiedIcpsState = [];
      if (response?.data?.data?.icps) {
        peVerifiedIcpsState = buildPeVerifiedIcpsState(response.data.data.icps, owner_id_map_company_name);
      }
      const totalPages = response?.data?.data?.total_pages;
      if (((totalPages - 1) === 0) || !response?.data?.data?.icps) {
        handleOpenSnackbar("Fetched all ICPS with the current filters applied", "info");
      }

      setWorkDoneCount(workDoneResponse.data.data.work_done_count);
      setPeVerifiedIcps(peVerifiedIcpsState);
      setCustomersData(owner_id_map_company_name);
      resetManualSearchState();
      //! This is harcoded since it assumes it will be called at initial and next page is guaranteed to be 1
      setIcpPage(1);

      return peVerifiedIcpsState;
    } catch (e) {
      console.error(e);
      handleOpenSnackbar("Error in fetching PE verified icps", "error");
      } finally {
        setIsLoading(false);
        setPeVerifiedIcpsDropdownLoading(false);
    }
  };

  const handleOnChangePeVerifiedIcps = async (newValue, actionMeta) => {
    if (actionMeta.action === "select-option") {
      console.log(
        "before making fetch by icp id: ",
        newValue,
        " :action: ",
        actionMeta
      );
      setPage(0);
      setData([]);
      setPeDropdownSelectedValue(newValue);
      fetchData(
        false,
        0,
        false,
        newValue?.value,
        true,
        newValue?.customer_name ?? null,
        newValue?.account_id ?? null
      );

      resetManualSearchState();
      setSelectedProfileId(null);
    }
  };

  // preservePrevData is used for load more
  // resetPage is used when saved - then fetch all
  // filterData when filter out the profile you took action on
  // icp_id
  // showLoader - when scrolling/fetching more for inifinte scroll dont show the full page loader
  const fetchData = async (
    preservePrevData = false,
    resetPage = false,
    filterData = false,
    icp_id,
    showLoader = true,
    customer_name = null,
    account_id = null
  ) => {
    try {
      setEndOfPageReached(false);

      if (filterData) {
        setData((prevState) =>
          prevState.filter((data, index) => data.profile_id !== selectedProfileId)
        );
        setSelectedProfileId(null);

        return;
      }

      if (showLoader) {
        setIsLoading(true);
      }

      let response_fetch = await axios.post(url.rtFetchProfilesByIcpId, {
        page: resetPage !== false ? resetPage : page,
        icp_id,
        mode: "manual",
      });

      const all_icps = [];
      let stateData = [];
      // TODO: implement this later - if end of page reached then show appropriate message and hide "load more button"
      const endOfPageReachedTemp =
        response_fetch.data?.data?.profiles?.length > 0 ? false : true;

      if (endOfPageReachedTemp) {
        handleOpenSnackbar("Fetched all profiles", "info");
        setShowLoadMoreButton(false);
        setEndOfPageReached(true);
        return;
      }

      // take icp from pe dropdown
      all_icps.push(icp_id);

      // new api returns incorrect icp_ids - take icp from pe dropdown

      let response_icp_details = await axios.post(url.getIcpsDetails, {
        icp_ids: all_icps,
      });
      const icp_map_to_icp_object = new Map();
      for (let data of response_icp_details.data.data) {
        icp_map_to_icp_object.set(data.meta.icp_id, data);
      }

      // change this to prod api beore live
      let response_customers = await axios.get(url.customersDataPilot);
      const account_id_map_company_name = response_customers.data.response;

      const payload_fetch_full_names = {
        sandbox_profile_ids: {},
        public_profile_ids: [],
      };
      const prids = [];

      let owner_id_map_company_name;
      
      // if didnt make the initial populatePeVerifiecIcpsDropdown call then this might be empty
      if (!customersData) {
        owner_id_map_company_name = await wrapperCustomersData();
        setCustomersData(owner_id_map_company_name);
      }

      for (let data of response_fetch.data.data.profiles) {
        const profile_id = data["profile_id"];
        const sandbox_name = data["sandbox_name"];
        prids.push(profile_id);

        if (sandbox_name) {
          payload_fetch_full_names["sandbox_profile_ids"][profile_id] =
            sandbox_name;
        } else {
          payload_fetch_full_names["public_profile_ids"].push(profile_id);
        }
      }

      let rt_copilot_assigned_users = await axios.post(
        url.rtCopilotAssignedUsers,
        {
          prids: prids,
        }
      );
      rt_copilot_assigned_users =
        rt_copilot_assigned_users.data.data.prid_to_assigned_user;

      const response_fetch_profile_names = await axios.post(
        url.fetchProfilesNamesByRefs,
        payload_fetch_full_names
      );

      console.log(
        "full name details",
        response_fetch_profile_names?.data?.data
      );
      const profile_names_to_full_name_last_ingested_at_obj =
        response_fetch_profile_names?.data?.data;

      for (let data of response_fetch.data.data.profiles) {
        const profile_id = data["profile_id"];
        const assigned_user = rt_copilot_assigned_users[profile_id]["user"];
        const full_name =
          profile_names_to_full_name_last_ingested_at_obj[profile_id]?.[
            "full_name"
          ];

        // some profiles dont exist in db - skip showing these
        if (full_name === undefined || full_name === null) {
          continue;
        }

        const linkedin_uid =
          profile_names_to_full_name_last_ingested_at_obj[profile_id]?.[
            "linkedin_uid"
          ];
        // last_ingested_at is the last refreshed
        const last_ingested_at =
          profile_names_to_full_name_last_ingested_at_obj[profile_id]?.[
            "last_ingested_at"
          ];
        const icp_object = icp_map_to_icp_object.get(icp_id);
        console.log("ICP OBJECT: ", icp_object);
        const owner_id = icp_object["meta"]["owner_id"];
        const icp_name = icp_object.name;
        const icp_created_at = icp_object.meta.createdAt;
        const icp_updated_at = icp_object.updated_at;
        const icp_search_link = `${SEARCH_LINK}/${icp_id}?joid=${owner_id}&redirect_joid=${owner_id}`;
        console.log("DUMMY: ", peDropdownSelectedValue);
        const ep_link = `${ENRICHED_PROFILE_LINK}/${data["profile_id"]}?state=/searches/${icp_id}&icpid=${icp_id}&joid=${owner_id}&aId=${account_id}`;
        data["icp_type"] = icp_object.type;
        // { company_name, account_id }
        let ownerIdToCompanyNameAndAccountId = null;

        if (customersData) {
          ownerIdToCompanyNameAndAccountId = customersData.get(owner_id);
        } else {
          ownerIdToCompanyNameAndAccountId = owner_id_map_company_name;
        }
        
        data["account_id"] = account_id ?? ownerIdToCompanyNameAndAccountId?.account_id;
        data["customer_name"] = customer_name ?? decodeString(ownerIdToCompanyNameAndAccountId?.company_name);

        console.log("APPPLES: ", data);

        stateData.push({
          ...data,
          assigned_user,
          icp_id,
          icp_name,
          owner_id,
          icp_search_link,
          ep_link,
          full_name,
          last_ingested_at,
          icp_created_at,
          icp_updated_at,
          linkedin_uid,
        });
      }

      if(stateData.length !==0 ){
        stateData = await pushContactInfoIntoProfiles(stateData)
      }
      
      
      /*
        Fetching data scenarios
            load more will keep adding more data building on prev data
            hitting save will make page size 0 and set fresh data

            save contact info will call fetch contact info by prid and add it back to that index
      */
      if (stateData.length === 0) {
        console.log("No profile name found in all the profiles, fetching again ", " reset page: ", resetPage, " page: ", page);
        fetchData(preservePrevData, resetPage + 1, filterData, icp_id, showLoader, customer_name, account_id);
        setPage((page) => page + 1);
      } else {
        if (preservePrevData) {
          setData((prevState) => prevState.concat(stateData));
        } else {
          setData(stateData);
        }
      }

      setSelectedProfileId(null);
    } catch (error) {
      handleOpenSnackbar(`Failed to fetch data for ICP: ${icp_id}`, "error");
      console.log(error);
    } finally {
      if (showLoader) {
        setIsLoading(false);
      }
    }
  };

  const filteredData = useMemo(()=>{
    const newData = data.filter((profile) => {
      if(showPersonalEmailsOnlyProfiles && !profile?.contact_info?.personal_emails_available){
        return false
      }
      return true
    })
    return newData;
  },[data, showPersonalEmailsOnlyProfiles])

  const handleRefreshData = async () => {
    window.location.reload();
    // try {
    //   // setIsLoading(true);
    //   const account_id = peDropdownSelectedValue.account_id;
    //   const customer_name = peDropdownSelectedValue.customer_name;
    //   const icp_id = peDropdownSelectedValue.icp_id;
    //   const allIcpsDetails = await populatePeVerifiedIcpsDropdown();

    //   if (
    //     allIcpsDetails.every((icpDetail) => {
    //       return icpDetail.icp_id !== icp_id;
    //     })
    //   ) {
    //     setData([]);
    //     setPeDropdownSelectedValue("");
    //     return;
    //   }

    //   handleOpenSnackbar("Data Refreshed", "info");
    // } catch (e) {
    //   console.log(e);
    //   handleOpenSnackbar("Error in refreshing data", "error");
    // } finally {
    //   setSelectedIndex(null);
    // }
  };

  const handleSaveStatus = async () => {
    handleCloseStatusVerificationModal();
    try {
      setIsLoading(true);

      const selectedData = data.find((datum) => datum.profile_id === selectedProfileId);
      const profile_id = selectedData["profile_id"];
      const owner_id = selectedData["owner_id"];
      const icp_search_link = selectedData["icp_search_link"];
      const customer_name = selectedData["customer_name"];
      const ep_link = selectedData["ep_link"];
      const full_name = selectedData["full_name"];
      const last_ingested_at = selectedData["last_ingested_at"];
      const account_id = selectedData["account_id"];
      const account_ids = selectedData["account_ids"];
      const channel = selectedData["channel"];
      const priority = selectedData["priority"];
      const sandbox_name = selectedData["sandbox_name"];
      const prev_verification_state_object = selectedData["verification_state"];
      const verification_state_ts =
        prev_verification_state_object["verification_state_ts"];
      const prev_verification_status = prev_verification_state_object["status"];
      const prev_verification_note =
        prev_verification_state_object["notes"] ||
        prev_verification_state_object["reason"];
      const icp_id = selectedData["icp_id"];
      const icp_name = decodeString(selectedData["icp_name"]);
      const icp_created_at = selectedData["icp_created_at"];
      const updated_at = selectedData["updated_at"];
      const icp_type = selectedData["icp_type"];

      // this obj is very heavy and we dont need to track it - increases payload size a lot and fails the nginx default config upload limit
      const keysToRemoveAts = ["job_activity", "ats_pids", "tags"];

      const ats_info_audit_log = contactInfoData?.ats_info?.map(obj => {
        const updatedObj = { ...obj };
        keysToRemoveAts.forEach(key => delete updatedObj[key]);
        return updatedObj;
      }) ?? [];

      let auditLogData = {
        eventName: "verification_status_rt_copilot_search",
        eventType: "save",
        userId: loggedInUserDetails._id,
        eventMetaData: {
          candidateId: profile_id,
          candidateName: full_name,
          customerName: customer_name ? customer_name : "not specified",
          shortlistLink: icp_search_link,
          shortlistDate: icp_created_at,
          searchName: icp_name,
          ownerId: owner_id,
          epLink: ep_link,
          lastIngestedAt: String(last_ingested_at),
          // value from dropdown
          accountId: account_id,
          // value as is from fetch by icp api
          accountIds: account_ids,
          channel: channel,
          priority: priority,
          sandboxName: sandbox_name,
          icpId: icp_id,
          status: status,
          comment: messageRef?.current?.value,
          profileUpdatedAt: updated_at,
          icpType: icp_type
        },
        oldRecord: {
          publicEmails: contactInfoData.public_emails,
          linkedins: contactInfoData.linkedins,
          sandboxEmails: contactInfoData.sandbox_emails,
          goldenEmails: contactInfoData.golden_emails,
          sandboxPhones: contactInfoData.sandbox_phones,
          globalPhones: contactInfoData.global_phones,
          sandboxRemovedEmails: contactInfoData.sandbox_removed_emails,
          publicRemovedEmails: contactInfoData.public_removed_emails,
          sandboxAtsEmails: contactInfoData.sandbox_ats_emails,
          globalAtsEmails: contactInfoData.global_ats_emails,
          atsInfo: ats_info_audit_log,
          verificationStateTs: String(verification_state_ts),
          status: prev_verification_status,
          comment: prev_verification_note,
        },
        newRecord: {},
      };

      const response = await axios.post(url.updateStatusRtProfile, {
        profile_id: profile_id,
        message: messageRef?.current?.value,
        verification_state: status,
        audit_logs: auditLogData,
      });

      resetFormStates();
      // setPage(0);
      // just remove the selected profile from data
      setData((prevState) =>
        prevState.filter((data, index) => data.profile_id !== selectedProfileId)
      );
      let workDoneResponse = await fetchRtWorkDone();
      setWorkDoneCount(workDoneResponse.data.data.work_done_count);
      setSelectedProfileId(null);
      // fetchData(undefined, 0, true);
      handleOpenSnackbar("Successfully marked status", "success");
    } catch (e) {
      console.log(e);
      handleOpenSnackbar("Failed to mark status", "error");
    } finally {
      setIsLoading(false);
    }
  };

  // resets all form states in the right pane container
  // can be used after selected index is changed, after save is done, added contact info etc
  const resetFormStates = () => {
    setStatus("");
    setSaveNotes("");
  };

  const handleClickLeftPane = (index) => (e) => {
    console.log("index: ", index, "clicked event", e.target);
    setSelectedProfileId(index);
    resetFormStates();
  };

  const handleShowScrollButton = (e) => {
    console.log("handleShowScrollButton run");
    if (e.currentTarget.scrollTop > 300) {
      setShowScrollToTopButton(true);
    } else {
      setShowScrollToTopButton(false);
    }
  };

  const scrollToTop = () => {
    console.log("scrollToTop run ", scrollToTopButtonRef);
    scrollToTopButtonRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // data for right pane
  const rightPaneData = useMemo(() => {
    return data.find((datum) => datum.profile_id === selectedProfileId);
  }, [selectedProfileId]);

  const handleLoadMoreData = () => {
    // setPage((page) => page + 1);

    // fetchData(true, page + 1);
    // for (let i = 20; i < 40; i++) {
    //   fetchData(true, i);
    // }
    setShowLoadMoreButton(false);
  };

  const handleSearchIcp = () => {
    console.log("handleSearchIcp ", searchIcpRef.current.value);
    // page dont matter for now - later implement properly
    // TODO: page should be set carefully
    setPage(0);
    // fetchData(false, 0, false);
    // handleSearchIcp();
    //TODO:  proper pagination should be implemented - "load more button" - make it bettter
    for (let i = 0; i < 10; i++) {
      fetchData(false, i);
    }
  };

  const onShowTaskAssignedToCheckedChange = (checked) => {
    if (checked) {
      setShowTaskAssignedTo(true);
    } else {
      setShowTaskAssignedTo(false);
    }
  };

  const handleFetchManualIcpId = (e) => {
    setData([]);
    setSelectedProfileId(null);
    resetDropdownSelectedIcp();
    fetchData(
      false,
      0,
      false,
      manualSearchIcpId?.trim(),
      true,
      null,
      null
    );
  }

  const resetManualSearchState = () => {
    setManualSearchIcpId("");
    setPage(0);
  }

  const resetDropdownSelectedIcp = () => {
    setPeDropdownSelectedValue({});
    setPage(0);
  }

  return (
    <div>
      <Header
        loggedInUserDetails={loggedInUserDetails}
        logoutUser={logoutUser}
      />
      <TabsHomePage />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <p>
          <Button
            variant="outlined"
            startIcon={<RefreshIcon />}
            color="secondary"
            onClick={handleRefreshData}
          >
            Refresh
          </Button>
        </p>
        <p>
          <Switch
            checkedChildren="Showing all profiles"
            unCheckedChildren="Showing only assigned profiles"
            className="mx-5"
            defaultChecked
            onChange={onShowTaskAssignedToCheckedChange}
          />
        </p>
        <p>Work done for today in RT Copilot Search: {workDoneCount}</p>
      </div>
      <Paper
        sx={{ mt: 3, mx: 3, p: 0 }}
        elevation={5}
        style={{ display: "flex" }}
      >
        {!isLoading ? (
          <>
            <LeftPane
              data={filteredData}
              selectedProfileId={selectedProfileId}
              handleClickLeftPane={handleClickLeftPane}
              handleShowScrollButton={handleShowScrollButton}
              scrollToTopButtonRef={scrollToTopButtonRef}
              showScrollToTopButton={showScrollToTopButton}
              scrollToTop={scrollToTop}
              handleLoadMoreData={handleLoadMoreData}
              searchIcpRef={searchIcpRef}
              showLoadMoreButton={showLoadMoreButton}
              isLoading={isLoading}
              peVerifiedIcpsDropdownLoading={peVerifiedIcpsDropdownLoading}
              peVerifiedIcps={filteredPeVerifiedIcps}
              handleOnChangePeVerifiedIcps={handleOnChangePeVerifiedIcps}
              peDropdownSelectedValue={peDropdownSelectedValue}
              fetchData={fetchData}
              page={page}
              setPage={setPage}
              endOfPageReached={endOfPageReached}
              scrollableContainerRef={scrollableContainerRef}
              populatePeVerifiedIcpsDropdown={populatePeVerifiedIcpsDropdown}
              showTaskAssignedTo={showTaskAssignedTo}
              loggedInUserDetails={loggedInUserDetails}
              handleShowCustomerSearches={handleShowCustomerSearches}
              showCustomerSearches={showCustomerSearches}
              handleRefreshData={handleRefreshData}
              handleIncrementIcpPage={handleIncrementIcpPage}
              showOnDemandSearches={showOnDemandSearches}
              showPersonalEmailsOnlyProfiles={showPersonalEmailsOnlyProfiles}
              handlePersonalEmailsOnly={handlePersonalEmailsOnly}
              handleShowOnDemandSearches={handleShowOnDemandSearches}
              handleManualSearchIcpIdChange={handleManualSearchIcpIdChange}
              manualSearchIcpId={manualSearchIcpId}
              handleFetchManualIcpId={handleFetchManualIcpId}
            />
            <RightPaneContainer
              loggedInUserDetails={loggedInUserDetails}
              data={rightPaneData}
              selectedProfileId={selectedProfileId}
              handleStatusChange={handleStatusChange}
              status={status}
              handleOpenSnackbar={handleOpenSnackbar}
              handleCloseSnackbar={handleCloseSnackbar}
              fetchData={fetchData}
              setEmailsToDisplay={setEmailsToDisplay}
              emailsToDisplay={emailsToDisplay}
              phoneNumbersToDisplay={phoneNumbersToDisplay}
              setPhoneNumbersToDisplay={setPhoneNumbersToDisplay}
              contactInfoData={contactInfoData}
              setContactInfoData={setContactInfoData}
              removedEmailsToDisplay={removedEmailsToDisplay}
              setRemovedEmailsToDisplay={setRemovedEmailsToDisplay}
              directEmailsFromATS={directEmailsFromATS}
              setDirectEmailsFromATS={setDirectEmailsFromATS}
            />
          </>
        ) : (
          <Loading />
        )}

        {
          // modal for when setting the vefification status
        }
        {openStatusVerificationModal && (
          <GenericModal
            id={"generic-rt-copilot-searches-modal"}
            open={openStatusVerificationModal}
            handleClose={handleCloseStatusVerificationModal}
            handleConfirm={handleSaveStatus}
            title={"Confirm Status"}
            status={status}
            message={
              <>
                <Typography
                  variant="body1"
                  component="p"
                  gutterBottom
                  sx={{ mb: 2 }}
                >
                  {"Name: "}
                  <b>
                    {rightPaneData && rightPaneData.full_name
                      ? decodeString(rightPaneData.full_name)
                      : "Not Available"}
                  </b>
                </Typography>
                <div>
                  Mark status as{" "}
                  <Chip
                    label={status}
                    color={status === "verified" ? "success" : "error"}
                  />
                </div>
              </>
            }
            messageRef={messageRef}
          />
        )}
        {openNewTaskAssignmentTriggered && (
          <MuiModal
            open={openNewTaskAssignmentTriggered}
            handleClose={handleCloseNewTaskAssignmentTriggered}
            title={"Task Assignment details changed"}
            message={"Please refresh your browser to see the changes"}
          />
        )}

        {snackbarDetails.open && (
          <GenericSnackbar
            id={"staus-update-snackbar"}
            open={snackbarDetails.open}
            handleClose={handleCloseSnackbar}
            message={snackbarDetails.message}
            severity={snackbarDetails.severity}
          />
        )}
      </Paper>
    </div>
  );
};

export default RTCopilotSearches;
