import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

const CompletedTasksCard = ({ tasksCompletedForToday }) => {
    return (
        <Card
            sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: "background",
                maxWidth: 300,
                p: 1,
            }}
        >
            <CheckCircle sx={(theme) => ({ fontSize: theme.custom.iconSizes.medium, color: theme.palette.success.light })} />

            <Typography sx={{ p: 1 }} variant="body1" color="text.secondary">
                {tasksCompletedForToday} Tasks Completed Today
            </Typography>
        </Card>
    );
};

export default CompletedTasksCard;
