import { createTheme } from "@mui/material/styles";
import baseTheme from "./baseTheme";
export const lightTheme = createTheme({
    ...baseTheme,
    palette: {
        mode: "light",
        common: {
            black: "#000",
            white: "#fff",
        },
        primary: {
            main: "#1976d2",
            light: "#42a5f5",
            dark: "#1565c0",
        },
        background: {
            default: "#FFFFFF",
            paper: "#FFFFFF",
            secondary: "#F4F5F7",
        },
        text: {
            primary: "#000000",
            secondary: "#6B7280",
        },
        warning: {
            main: "#ed6c02",
            light: "#ff9800",
            dark: "#e65100",
        },
        success: {
            main: "#2e7d32",
            light: "#4caf50",
            dark: "#1b5e20",
        },
        error: {
            main: "#d32f2f",
            light: "#ef5350",
            dark: "#c62828",
        },
        divider: "rgba(0, 0, 0, 0.12)",
        profileCreatorForm: {
            main: "#e3f2fd",
            workexp: "#fce4ec",
            company: "#c8e6c9",
            projects: "#a5d6a7",
            schoolInfo: "#fff176",
            location: "#ffccbc",
            duration: "#d7ccc8",
            ethnicity: "#f8bbd0",
            roleAndGroup: "#81c784",
            education: "#bbdefb",
            educationDetails: "#d1c4e9",
            degree: "#b39ddb",
            major: "#7e57c2",
            awards: "#ffb74d",
            endorsements: "#ffa726",
            certifications: "#ffab91",
            volunteerExperience: "#ffe082",
            languages: "#90caf9",
        },
        border: {
            primary: "1px solid #091E4224",
            thick: "2px solid #091E4224",
            et: "3px solid #091E4224",
        },
        action: {
            active: "#1976d2",
            hover: "rgba(25, 118, 210, 0.08)",
            selected: "rgba(25, 118, 210, 0.16)",
            disabled: "rgba(0, 0, 0, 0.38)",
            disabledBackground: "rgba(0, 0, 0, 0.12)",
            focus: "rgba(25, 118, 210, 0.12)",
        },
        monacoTheme: "vs-light",
    },
});
